.tableButton-container {
    width: fit-content;
    height: 37px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(196, 196, 196);

    font-size: var(--Button-font-size);

    color: rgb(72, 72, 72);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0px 10px;
    cursor: pointer;
    justify-content: center;
}

.image {
    width: 20px;
    height: auto;
    object-fit: contain;
}

.tableButton-container:hover  {
    /* background-color: var(--Primary-Color); */
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(134, 134, 134);
    color: rgb(0, 0, 0);
}

.tableButton-container:hover .image  {
    filter: invert(100%);
}

@media screen and (max-width: 800px) {

    .tableButton-container {
        width: auto;
        height: auto;
        background-color: rgb(255, 255, 255);
        font-size: 14px;
        white-space: nowrap;
    }

}

@media screen and (max-width: 1500px) {

    .tableButton-container {
        width: fit-content;
        height: 37px;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        border: 1px solid rgb(196, 196, 196);
    
        font-size: 11px;
    
        color: rgb(72, 72, 72);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 0px 10px;
        cursor: pointer;
        justify-content: center;
    }

}
