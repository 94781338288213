.card {
  /* width: 473px; */
  /* height: fit-content; */
  background-color: white;
  border-radius: 12px;
  color: black;

  /* min-width: 1500px; */
  width: 100%;
  height: 100%;
  height: fit-content;
  padding: 15px 0 30px 0;
}