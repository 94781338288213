html {
    box-sizing: border-box
}

*,
*:before,
*:after {
    box-sizing: inherit
}


.w3-container {
    height: 100%;
    width: 100%;
    
    min-height: 540px;
    /* max-height: 814px; */
    /* overflow-y: scroll; */
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* For WebKit browsers */
.w3-container::-webkit-scrollbar {
    width: 6px;
}

.w3-container::-webkit-scrollbar-thumb {
    background-color: rgb(174, 174, 174);
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Border radius of the scrollbar thumb */
}

.w3-container::-webkit-scrollbar-track {
    background-color: rgb(78, 78, 78);
    /* Color of the scrollbar track */
    border-radius: 10px;
    /* Border radius of the scrollbar track */
}

.w3-table,
.w3-table-all {
    border-spacing: 0;
    width: 100%;
    height: 100%;
    display: table;
    border-radius: 15px;



    



    /* position: relative; */
}

.w3-table-all {
    /* border: 1.5px solid  rgb(26, 16, 55); */

}

.w3-table-all thead {
    user-select: none;

    position: sticky;
    top: 0;
    z-index: 0;
}

.w3-table-all th {
    background-color: rgb(255, 255, 255);

}

.w3-table-all tbody tr {
    height: 55px;
}

.w3-table-all tbody tr:hover {
    /* background-color: var(--Background-Primary); */
}

.w3-striped tbody tr:nth-child(even) {
    background-color: #f1f1f1;

}

.w3-table-all tr:nth-child(odd) {
    background-color: #ffffff;

}

.w3-table-all tr:nth-child(even) {
    background-color: #f2f2f2;
}

.w3-hoverable tbody tr:hover,
.w3-ul.w3-hoverable li:hover {
    background-color: #ccc;

}

.w3-centered tr th,
.w3-centered tr td {
    text-align: center;

}

.w3-table td,
.w3-table th,
.w3-table-all td,
.w3-table-all th {
    padding: 12px 8px;
    display: table-cell;
    text-align: left;
    vertical-align: center;
}

/* Assuming your header element is h1, adjust it accordingly if it's different */

/* border colors */
.w3-table-all tr th {

    border-bottom: 2.3px solid #9f9f9fd4;
}


.w3-table-all td {

    /* border-bottom: 0.8px solid #c8c8c8; */

    border-radius: 0px;
}




.w3-table td,
.w3-table th,
.w3-table-all th {
    /* border-radius: 15px; */
}

.w3-table-all td,
.w3-table-all th {
    /* width: clamp(25px, 50px, 90px); */
    font-size: 14px;
}

.w3-table th:first-child,
.w3-table td:first-child,
.w3-table-all th:first-child,
.w3-table-all td:first-child {
    padding-left: 50px;
    white-space: nowrap;
    /* width: clamp(15px, 30px, 50px); */
}

.w3-table-all td:nth-child(1) {
    width: 440px;
    padding-left: 50px;
    /* background-color: red; */
}

.w3-table-all td:nth-child(2),
.w3-table-all th:nth-child(2) {
    width: 440px;
}

 .w3-table-all td:nth-child(3),
.w3-table-all th:nth-child(3) {
    width: 440px;
    word-wrap: break-word; /* Allow long words to break and wrap */
    overflow-wrap: break-word; /* Ensure compatibility with modern browsers */

}

.w3-table-all td:nth-child(4),
.w3-table-all th:nth-child(4) {
    width: 400px; /* Set maximum width */
    word-wrap: break-word; /* Allow long words to break and wrap */
    overflow-wrap: break-word; /* Ensure compatibility with modern browsers */
}


.w3-table-all td:nth-child(5),
.w3-table-all th:nth-child(5) {
    padding-left: 120px;
    width: 470px;
}
/* 
.w3-table-all td:nth-child(5),
.w3-table-all th:nth-child(5) {
    width: clamp(15px, 30px, 50px);
}

.w3-table-all td:nth-child(6),
.w3-table-all th:nth-child(6) {
    width: clamp(15px, 30px, 50px);
}

.w3-table-all td:nth-child(6),
.w3-table-all th:nth-child(6) {
    width: clamp(15px, 30px, 50px);
}

.w3-table-all td:last-child,
.w3-table-all th:last-child {
    width: clamp(15px, 30px, 50px);
} */


/* 
.w3-btn,
.w3-button {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap
}

.w3-btn:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.934), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.w3-btn,
.w3-button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
} */

.text {}

.paginationButtons-container {
    display: flex;
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    gap: 10px;
}

.paginationButtons-container h4 {
    color: #290a4c;
    margin: 0px;
    font-size: 18px;

}

.pagination_button {
    display: flex;
    /* Button Size */
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background-color: unset;
    border: 0px;
    border-radius: 50%;
    padding: 5px;
}

/* Prev & Next Table Page Button Disabled Styling */
.pagination_button:disabled,
.pagination_button:disabled:hover {
    background-color: rgba(128, 128, 128, 0);
}


.button_image {
    width: 20px;
    height: 100%;
    border: 0px;
}

/* When focusing on the button the button, the outline colour will change*/
.pagination_button:focus {
    outline: 2px solid #290a4c;
}

.currentPage-box {
    height: 40px;
    width: 35px;
    border-radius: 12px;
    text-align: center;
    border: 1px solid black;
}

.currentPage-box[type="number"]::-webkit-inner-spin-button,
.currentPage-box[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.currentPage-box[type="number"] {
    -moz-appearance: textfield;
}

.dropdown {
    /* display: none; */
    border: unset;
}

.pageSize-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(196, 196, 196);
    height: 40px;
    width: 100px;
    border-radius: 6px;

    position: relative;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
}


.selected-option {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    margin-left: 8px;
}

.arrow.up {
    transform: rotate(180deg);
}

.options {
    display: none;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #000000;
    top: 100%;
    /* Adjust this value as needed */
    border-radius: 0 0 5px 5px;
    position: absolute;
    top: 100%;
}



.options li {
    padding: 10px;
    cursor: pointer;
}

.options li:hover {
    background-color: #f1f1f1;
}

.headerRow {
    position: sticky;
    z-index: 1;
    top: 0;
}

.header {
    color: rgb(82, 82, 82);
    font-weight: 600;
    font-size: 19px;
}
