.card {
    /* width: 505px; */
    min-width: 350px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 12px;
    color: rgb(98, 98, 98);
    box-shadow: 0px 0px 3px rgb(196, 196, 196);
}

.headerBar{
    height: 15px;
    width: 100%;
    border-radius:12px 12px 0px 0px ;
    box-shadow: 1px 5px 5px;
    background-color: red;
}

 @media screen and (max-width: 300px) {
    .card {
        min-width: 300px;
    }

} 

.EyeSpyFX {
    background: rgb(100, 149, 237);
  }
  
  .RESURE {
    background: rgb(75, 0, 130);
  }
  
  .PARFAS {
    background: rgb(0, 0, 0);
  }
  
  .PORTAL_SECURITY {
    background: rgb(0, 0, 139);
  }
  
  .SOLO {
    background: rgb(178, 34, 34);
  }
  
  .KNOWIO{
    background: rgb(20, 155, 8);
  }
  
  .CPS{
    background: rgb(8, 155, 121);
  }
  
  .CSC_SECURITY{
    background: rgb(8, 150, 155);
  }
  .EES{
    background: rgb(94, 8, 155);
  }
  
  .defaultCompany {
    background: rgb(110, 110, 110);
  }