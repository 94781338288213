.container {
    width: 100%;
    height: fit-content;

    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    align-items: start;
    justify-content: space-between;
    background-color: rgb(247, 247, 247);
    margin-bottom: 10px;
}
.container h1 {
    font-size: 25px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
    color: #6b6b6b;
}
.container h3 {
    font-size: 15px;
    color: #939393;
}

.scrollableList {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #168aad transparent; /* Firefox */
}

.scrollableList::-webkit-scrollbar {
    width: 8px;
}

.scrollableList::-webkit-scrollbar-thumb {
    background: #168aad;
    border-radius: 10px;
}

.scrollableList::-webkit-scrollbar-track {
    background: #168aad;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top: 4px solid #168aad;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}