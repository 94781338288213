.card {
    background-color: #ffffff;
    border-radius: 8px;
    width: 520px;
    height: fit-content;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.Title {
    font-size: 45px;
    font-weight: 500;
    margin: 20px;
    margin-top: 0px;
    margin: 10px;
    color: rgb(73, 73, 73);
}

.subInfo {
    display: block;
    word-wrap: break-word;
    width: 80%;
    margin: 0px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: rgb(73, 73, 73);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 22px;
    gap: 20px;
    background-color: red;
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 30px;
    margin: 0;
}

.listItem{
    display: flex;
    justify-content: center;
}

.serviceLink {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
}

.extraLinks-list {
    list-style: none;
    padding: 0;
    /* margin-top: 10px; */
}

.extraLink {
    margin: 0px;
    color: rgb(20, 22, 52);
    font-size: var(--font-size-normal);
    font-weight: 400;

}

.listItem{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.link {
    color: rgb(20, 22, 52);
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    text-decoration: none;
}

.link:hover{
    color: rgb(175, 175, 175);
    cursor: pointer;   
}

.link:active{
    color: rgb(206, 206, 206);
    cursor: pointer;   
}

/* 
@media screen and (max-width: 1700px) {
    .card {
      
      
        width: 550px;
        padding: 15px;
    }

    .inputs-list .input {
        height: 60px;
        font-size: 18px;
    }

    .Title {
        font-size: 35px;
    }

    .subInfo {
        font-size: 16px;
    }
} */