.GroupCardContentLayout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    gap: 10px;
    cursor: pointer;
}

.header-bar {

    color: #05aec8;
}

.top-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    position: relative;
}

.middle-wrapper {
    width: 100%;
    height: 100%;

    display: flex;

    justify-content: space-between;
}



.siteDetails-layout {
    width: 100%;
    height: 100%;


    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.siteDetails-layout h3 {
    margin: 3px;
    font-size: var(--font-title);
    color: rgb(62, 62, 62);
}

.icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 85%;
}

.tagName {
    font-weight: 600;
    width: 80%;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.indicatorsCards-container {
    display: flex;
    flex-direction: flex;
    gap: 10px;
    align-items: flex-end;
}


.middle-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
}

.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 15px;
}

.bottomLeft-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    gap: 10px;

}

.userIcons {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

}

.ellipsis {
    width: fit-content;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;
}

.userIcon {
    background-color: var(--Primary-Colour);
}

.arrowButton {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.arrowButton:hover img {
    cursor: pointer;
    transform: translateX(10px);

}



.outputs {
    display: grid;


    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px;


}

.inputs {
    display: grid;


    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 5px;
}

.bottom-wrapper_divider {
    background-color: rgb(127, 127, 127);

    height: 65px;
    width: 1px;

    margin-left: 20px;
    margin-right: 20px;
}

.Icon {
    width: 30px;
    height: 30px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
}

/* .GroupCardContentLayout:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 23px;

} */

.GroupCardContentLayout:hover .arrowIcon {
    /* transform: translateX(10px); */
    /* Move the icon 10px to the right on active state */
}


.icons-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 91%;
    gap: 2px;
}


/* .icons-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
} */

.deleteIcon {
    cursor: pointer;
    color: #ff0000; /* Red color for delete */
    font-size: 1.2rem; /* Adjust size */
    margin-right: 8px; /* Add spacing if needed */
    margin-top: 4px; /* Adjust vertical spacing */
}

.deleteIcon:hover {
    color: #cc0000; /* Slightly darker on hover */
}

/* @media screen and (max-width: 600px) {
    .siteDetails-layout h3 {
        font-size: 20px;
    }

    .userIcons {
        display: none;
    }

} */