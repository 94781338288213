.activtyPage_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
}

.searchContainer {
  margin-left: 0px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Distribute space between items */
  align-items: center;
  height: 90px;
}


  /* .container {
    width: 100%;
    height: fit-content;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    margin-bottom: 20px;
} */

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto a new line */
  gap: 16px; /* Optional: Add space between items */
  margin-bottom: 20px;
}

.container .forceNewLine {
  flex-basis: 100%; /* Force this item to take the entire row */
}

.ModalIcon {
  width: 100px;
  height: 100px;
  /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
  background-repeat: no-repeat;
  /* Set the transition duration */
  transition: transform 0.3s;
}

/* .container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 35px;
  margin-bottom: 25px;
}
   */