.searchBar {
    border-radius: 8px;
    border: 0px;
    width: 100%;
    height: 60px;
    font-size: 20px;
    padding-left: 60px;
    /* Adjust the size of the icon */
    background-image: url('../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Power Search/__Search.svg');
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: 10px center;
  }
  
  .searchBar:focus {
    outline: 2px solid #290a4c;  
  }

  

  @media screen and (min-width: 1230px) {
    .searchBar{
      width: 1123px;
    }
  
  }
