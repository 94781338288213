.Pill_Widget {
    width: 90px;
    
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    border-radius: var(--button-border-radius);
    padding: 6px;
    background-color: grey;
    color: white;
    font-size: 14px;
}