.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
    padding: 20px;
    max-width: 100%;
    height: 509.69px;
    width: clamp(400px, 520px, 700px);
    /* padding-top: 15px; */
    gap: 20px;
}

.Title {
    font-size: 45px;
    font-weight: 500;
    margin: 20px;
    margin-top: 0px;
    margin: 0px;
    color: rgb(73, 73, 73);
}

.subInfo {
    display: block;
    word-wrap: break-word;
    width: 50%;
    margin: 0px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: rgb(73, 73, 73);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 22px;
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    /* gap: 30px; */
    margin: 0;
}

.inputs-list .input {
    background-color: rgb(202, 202, 202);
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 70px;
    padding-left: 20px;
    font-weight: 400;
    font-size: 22px;

}

.inputs-list .input::placeholder {
    color: rgb(67, 67, 67);
}

.inputs-list .input:focus {
    outline: rgb(20, 22, 52);
}

.serviceLink {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
    cursor: pointer;
}

.extraLinks-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.extraLink {
    margin: 25px;
    color: rgb(20, 22, 52);
    font-size: 16px;
}

.extraLink span {

    color: rgb(20, 22, 52);
    font-size: 16px;
    font-weight: 600;
    
}


@media screen and (max-width: 1700px) {

    .card {
        /* height: 500px; */
        /* width: 500px; */
        
    }
}



@media screen and (max-width: 1290px) {
   
    .card{
        border-radius: 8px;
    }
  }
   