.infoCard-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(196, 196, 196);
    height: 30px;
    width: fit-content;
    border-radius: 12px;

    position: relative;

    padding: 24px;
    color: rgb(83, 83, 83);
}