.button-container {
    width: fit-content;
        height: 55px;
        background-color: white;
        border-radius: 8px;
        border: 0px;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        padding-right: 22px;
        font-size: 15px;
        text-align: left;
        display: flex;
        gap: 15px;
        cursor: pointer;
        box-shadow: 0px 0px 3px rgb(196, 196, 196);;
}

.button-container:hover {
    background-color: var(--Primary-Color);
    color: white;
}

.active {
    background-color: var(--Primary-Color);
    color: white;
    border: 2px solid white;
}

.img {
    width: 35px;
    height: 100%;
}

.activeImg {
    filter: invert(100%);
}

.button-container:hover .img {
    filter: invert(100%);
}

@media screen and (max-width: 1500px) {
    .button-container {
        width: fit-content;
        height: 40px;
        background-color: white;
        border-radius: 8px;
        border: 0px;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;
        padding-right: 12px;
        font-size: 13px;
        text-align: left;
        display: flex;
        gap: 5px;
        cursor: pointer;
        box-shadow: 0px 0px 3px rgb(196, 196, 196);;
    }
    
    .button-container:hover {
        background-color: var(--Primary-Color);
        color: white;
    }
    
    .active {
        background-color: var(--Primary-Color);
        color: white;
        border: 0px solid white;
    }
    
    .img {
        width: 20px;
        height: 100%;
    }
    
    .activeImg {
        filter: invert(100%);
    }
    
    .button-container:hover .img {
        filter: invert(100%);
    }
}