body {
  overflow: hidden
}

.screen {
  height: 100vh;
  width: 100vw;

}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header-wrapper {
  height: auto;
  width: 100%;

  /* border-radius: 12px; */

}

.body-wrapper {
  flex-grow: 1;
  /* Allow to grow and take up available space */
  flex-shrink: 1;
  /* Allow to shrink if necessary */

  /* padding: 12px; */
  display: flex;
  flex-direction: row;

  height: 100%;
  width: auto;
  overflow-y: auto;
  /* background-color: #ebedf0; */
  background-color: #fafafa;

}

.nav-wapper {
  position: sticky;
  top: 0;
  z-index: 100;
}

.page-Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  /* Constrain the height to the viewport height */
  padding: 20px 30px;
  gap: 30px;
  overflow-y: scroll;
  /* Always show the vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
}

/* For WebKit browsers */
.page-Wrapper::-webkit-scrollbar {
  width: 16px;
  /* Increase width to create padding effect */
}

.page-Wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(120, 120, 120);
  ;
  border-radius: 10px;
  border: 3px solid transparent;
  /* Creates space around the thumb */
  background-clip: padding-box;
  /* Ensures the border doesn't overlap the background color */
}

.page-Wrapper::-webkit-scrollbar-track {
  background-color: rgb(195, 195, 195);

}



.loading-overlay {
  position: absolute;
  background-color: rgb(195, 195, 195);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 20%;
}

.loading-overlay h3 {
  height: fit-content;
}


.breadcrumb-Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headers {
  display: flex;
  flex-direction: row;
  gap: 200px;
}

.header-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(205, 205, 205, 00);
  height: 90px;
  width: 100%;
}

.header-data h2 {
  border-bottom: 1px solid black;
  font-size: 30px;
  font-weight: 300;
  margin: 0;
  cursor: pointer;
}


.header-data h2:hover {
  /* color: rgb(14, 209, 186); */
  border-bottom: 1px solid rgb(14, 209, 186);
  transform: scale(1.1);
}

/* Stats  container */

.stats-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
}

@media screen and (max-width: 1500px) {
  .page-Wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    /* Constrain the height to the viewport height */
    padding: 10px 10px;
    gap: 10px;
    overflow-y: scroll;
    /* Always show the vertical scrollbar */
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
  }
}

.breadcrumb-Wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}


/* @media screen and (max-width: 900px) {

  .body-wrapper {
    width: 100%;

  }

} */

/* @media screen and (max-width: 1757px) {
  .SearchCard {
  
    height: 300px;
    width: 1500px;
  }

}
*/