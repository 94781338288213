.page-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgb(20, 22, 52);
}

.container {
    display: flex;
    max-height: 540px;
    width: fit-content;
}

.card {
    border-radius: 0 8px 8px 0;
    /* height: 100%; */
    min-width: 400px;
    max-width: 100%;
    height: auto;
}

.imageContainer {
    border-radius: 12px 0px 0px 12px;
    width: 520px;
    height: 509.63px;
}


.image {
    position: relative;
    z-index: 99;
    border-radius: 12px 0 0 12px;

    height: 100%;
    width: 690px;
    /* object-fit: fill; */

}


/* @media screen and (max-width: 1700px) {

    .imageContainer {
      width: 550px;
    }
}

*/
@media screen and (max-width: 1290px) {

    .imageContainer {
        display: none;
    }
} 