.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: flex-end;
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 30px;
    margin: 0;
    position: relative;
    align-items: flex-end;
}

.label {
    position: absolute;
        top: -14px;
        left: 25px;
        background: white;
        padding: 6px;
}

.input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    height: fit-content;
    padding: 10px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 12px;
    gap: 10px;
}   

.pincodeButtonContainer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 10px;
}

.activeButton{
    background-color: var(--Primary-Color);
    border: 1px solid rgb(255, 255, 255);
    color: white;
}

.timeDatePicker-Container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.generateButtonLayout {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: last baseline;

}