
.userIcon-div{
    width: 35px;
    height: 35px;
    background-color: rgb(0, 140, 255);
    border: 1px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userIcon-div h2{
    margin: 0;
    font-size: var(--font-normal);
    font-weight: 900;
    color: rgb(255, 255, 255);
}