.pin-switch {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 5px;
    width: 210px;
    height: 130px;
    /* background-color: #404251; */
    /* background-image: linear-gradient(to right,#404251 , #374b53); */
    background-color: var(--Primary-Color-SiteView);
    /* border: 3px solid rgb(203, 203, 203); */
    color: white;
    user-select: none;

    position: relative;
}

.top {
    display: flex;

    align-items: flex-start;

    width: 100%;
    height: 33%;
    /* background-color: #ffbebe; */
}

.top-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.top .img {

    height: 30px;
    width: 30px;
    display: flex;
    justify-content: flex-end;
    filter: invert(100%);
}


.lable {
    font-size: 18px;
    font-weight: 400;
}


.middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    width: 100%;
    height: 33%;
    /* background-color: #fd1010; */
}

.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 34%;
}

.bottom .img {
    height: 50px;
    width: 50px;
}

.active {
    color: var(--Primary-Color);
}

.inactive {
    color: rgb(66, 66, 66);
}

.pin-switch:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.pin-switch:active {
    cursor: pointer;
    transform: scale(1);
    color: rgb(183, 183, 183);
}

/* .pin-switch .img:active{
    width: 100px;
    height: 100px;
    background-image: url("../../../../assets//appscreen_icons/default_closed_colour.png");
    z-index: 999;
} */

.HiddenIcon {
    filter: invert(100%);
    opacity: 0.1;
    z-index: 10;
}

.line {
    width: 10px;
    height: 100%;
    background-color: white;
    position: absolute;
    opacity: 0.05;
    transform: rotate(45deg);
}

@media screen and (max-width: 2100px) {
    .pin-switch {
        width: 190px;
        height: 120px;
    }

    .top h3 {
        font-size: 16px;
    }


    .bottom .img {
        height: 35px;
        width: 35px;
    }
}