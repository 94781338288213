.control{
  width: 290px;
  text-align: left;
}
  
  .control input:focus {
    outline: none;
    border-color: #4f005f;
    background: #f6dbfc;
    
  }
  
  .control.invalid input {
    border-color: red;
    background: #fbdada;
    transition: all .3s;
  }

  @media (min-width: 768px) {
    .control {
      align-items: center;
      flex-direction: row;
    }
  
  }