.layout {
    display: flex;
    width: fit-content;
    height: auto;
}

.pin-output {
    display: flex;
    flex-direction: row;


    width: 100%;
    width: 415px;
    height: 75px;
    /* background-image: linear-gradient(to right,#404251 , #374b53); */
    background-color: var(--Primary-Color-SiteView);
    border-radius: 12px 0 0 12px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    align-items: center;
    gap:5px;
}

.pin-output h3 {
    display: flex;
    flex-direction: row;
    align-items: left;
    color: white;
    font-weight: 400;
    font-size: 18px;
}

.img {
    transform: scale(.7);
    width: 50px;
    height: 50px;
    filter: invert(100%);
    margin: 15px;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;
}

.text-wrapper h2,
.pinName {
    margin: 0;
    text-align: left;
    align-items: flex-start;
    font-weight: 400;
    font-size: 18px;
}

.pinLable {
    display: flex;
    flex-direction: row;
    align-items: left;

}

.activated {

    color: rgb(3, 160, 92);
}

.deactivated {
    color: rgb(195, 12, 18);
}


.active {
    color: var(--Primary-Color)
}

.Inactive {
    color: rgb(128, 128, 128);
}


.pin-activated {
    width: 20px;
    height: auto;
    background-color: rgb(3, 160, 92);
    border-radius: 0 12px 12px 0;

}

.pin-deactivated {
    width: 20px;
    height: auto;
    background-color: rgb(195, 12, 18);
    border-radius: 0 12px 12px 0;

}

.pin-active {
    width: 20px;
    height: auto;
    background-color: var(--Primary-Color);
    border-radius: 0 12px 12px 0;
}


.pin-Inactive {
    width: 20px;
    height: auto;
    background-color: rgb(128, 128, 128);
    border-radius: 0 12px 12px 0;
}


/* 
.pin-output:hover {
    cursor: pointer;
    transform: scale(1.03);
} */

/* .layout:hover .pin-output,
.layout:hover .pin-activated {
    transform: scale(1.07);
}

.layout:hover .pin-output,
.layout:hover .pin-deactivated {
    transform: scale(1.07);
}

.pin-output:hover {
    width: 90%;
    border-radius: 12px 12px 12px 12px;
}

.pin-output:active {
    transform: scale(1);
    width: 100%;
    border-radius: 12px 12px 12px 12px;
} */

@media screen and (max-width: 2100px) {
    .layout {
        width: fit-content;
   
    }

    .pin-output {
        width: 374px;
        height: 75px;
    }

    .pin-output h3 {
        font-size: 16px;
    }


    .text-wrapper h2,
    .pinName {

        font-size: 18px;
    }
}