.container {
    display: flex;
    flex-direction: column;

    justify-self: center;
    align-items: center;
    width: 100%;
    position: relative;
}


/* style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", position: "relative", width: "100%" } */

.infoBox {
    height: 100px;
    width: 400px;
}

.backButton{
    display: flex;
    position: absolute;
    left: 5px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid black;
    width: 35px;
    height: 35px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}