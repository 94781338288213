.filter-button{
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: var(--button-border-radius);
    border: none;
    padding: 10px 50px;
}

.filter-button h2 {
    margin: 0px;
    font-size: 20px;
    
}

.filter-button:hover{
    background-color: var(--Primary-Color);
    color: white;
    transform: scale(1.1); /* Enlarge the button on hover */
    cursor: pointer;
}