.container {
    background-color: rgb(34, 202, 128);
    width: fit-content;
    height: 25px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
}

.container h2 {
    margin: 0px;
    font-size: 12px;
    color: white;

}

.styleLoading {
    background-color: rgb(180, 180, 180);
}

.style1 {
    background-color: rgb(34, 202, 128);
}

.style2 {
    background-color: rgb(253, 193, 106)
}


.style3 {
    background-color: rgb(255, 79, 112)
}

@media screen and (max-width: 920px) {
    .container {
        border-radius: 100%;
        width: 15px;
        height: 15px;
        padding: 0px
    }


    .container h2 {
        color: transparent;
        user-select: none;
    }
}

@media screen and (max-width: 740px) {

    .container {
        width: fit-content;
        height: 25px;
        border-radius: 6px;
        padding: 10px 15px;
    }


    .container h2 {
        margin: 0px;
        font-size: 12px;
        color: white;

    }

}



@media screen and (max-width: 430px) {
    .container {
        /* height:20px */
        padding: 5px 10px;
    }

    .container h2 {
        font-size: 10px;
    }
}