.StatusIndicatorCard {
    width: fit-content;
    height: 30px;
    border-radius:12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 10px;
    font-size: 12px;
    
    /* Add dynamic text scaling */
    font-size: clamp(8px, 1.5vw, 12px); /* Ensures the text scales dynamically */
    overflow: hidden; /* Prevents overflow */
    text-overflow: ellipsis; /* Adds ellipsis for overflowed text */
    white-space: nowrap; /* Keeps text in one line */
}

.StatusIndicatorCard h3 {
    word-wrap: break-word;
    text-align: center;
    font-size: inherit; /* Matches the parent font size */
    margin: 0; /* Removes default margin for <h3> */
}

.EyeSpyFX {
    background: rgb(100, 149, 237);
}

.RESURE {
    background: rgb(75, 0, 130);
}

.PARFAS {
    background: rgb(0, 0, 0);
}

.PORTAL_SECURITY {
    background: rgb(0, 0, 139);
}

.SOLO {
    background: rgb(178, 34, 34);
}

.KNOWIO{
    background: rgb(20, 155, 8);
}

.CPS{
    background: rgb(8, 155, 121);
}

.CSC_SECURITY{
    background: rgb(8, 150, 155);
}
.EES{
    background: rgb(94, 8, 155);
}

.defaultCompany {
    background: rgb(110, 110, 110);
}



@media screen and (max-width: 1500px) {
    .StatusIndicatorCard {
        /* width: 60px; */
        /* padding: 6px; */
        font-size: 12px;
    }

}