.contentContainer {
    width: 100%;
    min-height: 141px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;

    min-height: 141.5px;
}

.top{
    display: flex;
    justify-content: space-between;
}

.contentContainer h3 {
    margin: 0px;
    font-size: 20px;
    color: rgb(62, 62, 62);
    margin: 10px 0px 5px 0px;
}
 
.contentContainer h4 {
    margin: 0px;
    font-size: 16px;
    color: rgb(62, 62, 62);
}

.contentContainer h5 {
    margin: 0px;
    font-weight: 500;
    font-size: 15px;
    color: rgb(62, 62, 62);
}

.statusIndicator{
    width: 20px;
    height: 20px;
    background-color: rgb(231, 216, 0);
    border-radius: 50%;
}


.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;

    position: relative;
}

.arrowButton:hover img {
    cursor: pointer;
    transform: translateX(10px);

}

.Icon {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
}


.contentContainer:hover .Icon {
    /* Transform the icon on hover of contentContainer */
    transform: translateX(10px);
}

.editContainer{
    
    width: 80%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editContainer h3{
    font-weight: 400;
}

.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;   
}

.title {
    max-height: 100px;
    font-weight: 600;
    margin: 0px;
    font-size: 20px;
    width: fit-content;
}


.subtitle {
    max-height: 100px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
    width: fit-content;
}

@media screen and (max-width: 1500px) {
    .contentContainer {
        width: 100%;
        min-height: 141px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 25px 25px;
        padding-top: 8px;
        border-radius: 12px;
        text-decoration: none;
        /* gap: 10px; */
        cursor: default;
    }
    
    .top{
        display: flex;
        justify-content: space-between;
    }

    .subtitle {
        max-height: 100px;
        font-weight: 400;
        margin: 0px;
        font-size: 15px;
        width: fit-content;
    }

    .title {
        max-height: 100px;
        font-weight: 600;
        margin: 0px;
        font-size: 16px;
        width: fit-content;
    }
    
    .contentContainer h3 {
        margin: 0px;
        font-size: 16px;
        padding-bottom: 6px;
        color: rgb(62, 62, 62);
    }

    .contentContainer h4 {
        margin: 0px;
        font-size: 13px;
        color: rgb(62, 62, 62);
    }

    .contentContainer h5 {
        margin: 0px;
        font-size: 10px;
        color: rgb(62, 62, 62);
    }
    
    .statusIndicator{
        width: 20px;
        height: 20px;
        background-color: rgb(231, 216, 0);
        border-radius: 50%;
    }
    
    
    .bottom-wrapper {
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 20px;
    
        position: relative;
    }
    
    .arrowButton:hover img {
        cursor: pointer;
        transform: translateX(10px);
    
    }
    
    .Icon {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
        background-repeat: no-repeat;
        /* Set the transition duration */
        transition: transform 0.3s;
    }
    
    
    .contentContainer:hover .Icon {
        /* Transform the icon on hover of contentContainer */
        transform: translateX(10px);
    }
    
    .editContainer{
        
        width: 80%;
        height: 100%;
    
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .editContainer h3{
        font-weight: 400;
    }
    
    .buttonContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;   
    }

        
    .PillHeight{
        height: fit-content;
        font-size: 12px;
      }

      .subSubText {
        max-height: 100px;
        font-weight: 400;
        margin: 0px;
        font-size: 10px;
        width: fit-content;
      }
    
}