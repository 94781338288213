.listOption {
    background-color: var(--Primary-Color);
    border-radius: 12px;
    width: fit-content;
    max-width: 500px;
    height: fit-content;
    color: white;
    padding: 12px 20px 12px 20px;

    display: flex;
    flex-direction: column;
 
    gap: 10px;
}

.listOption h4 {
    margin: 0px;
}