/* .container{
    width: 270px;
    height: 100vh;
    padding: 30px;
} */

.nav-item.disabled {
    opacity: 0.5;
    pointer-events: none;
    background: blueviolet;
}


.side-nav {
    width: 100%;
    height: 100%;

    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* padding: 12px 20px; */
    z-index: 10;
    transition: width 0.3s ease;
    position: relative;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden;
}

.nav-wrapper {
    margin-top: 22px;
    width: 100%;
    height: 100%;
    /* background-color: rgb(199, 199, 199); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(127, 255, 212, 0);
    /* transition: width 0.3s ease; */
    margin-bottom: 35px;

}

.nav-listLinks {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 0px 12px;
    width: 100%;
    height: auto;
    /* background-color: rgb(160, 230, 54); */
    gap: 15px;
    padding: 12px 0px;
    margin: 0;
    background-color: rgba(0, 255, 255, 0);
}


.nav-listLinks li {
    list-style-type: none;
}

.nav-listSettings {
    width: 100%;
    height: 100px;
    background-color: rgb(36, 180, 215);
}


/* @media screen and (max-width: 1173px) {
 
    .side-nav {
        width: 100%;
     
        padding: 0;
        
        
    }

    .nav-wrapper{
        width: 100%;
        align-items: left;
        padding: 6px;
    }

} */

@media screen and (min-width: 2200px) {
    .side-nav {
        width: 105px;

    }
}

@media screen and (max-width: 1050px) {
    .side-nav {
        /* display: none; */
        width: 0px;
        position: absolute;
    }


}