.selectedOption {
    /* Any additional styles for selectedOption */
  }
  
  .selectedOptionContent {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    padding-left: 12px;
  }
  
  .img {
    width: 40px;
    height: 40px;
    filter: invert(100%);
  }

  .img:hover{
    filter: invert(0%);
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 5px;
  }

  .img:active{
    
    background-color: rgb(255, 72, 0);
    filter: invert(100%);
    border-radius: 50%;
    padding: 5px;
  }
  
  .selectedOptionTitle {
    color: white;
    font-weight: 400;
  }
  
  .dropDownContainer {
    background: #1d1d1d;
    left: 20px;
    top: 30px;
    z-index: 1000;
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    height: fit-content;
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .dropDownTitle {
    color: white;
  }
  
  .dropDownSubTitle {
    color: white;
    margin: 0px;
  }
  
  .iconList {
    display: flex;
    gap: 10px;
  }
  
  .iconItem {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .cancelButton {
    width: 100%;
    background-color: var(--Primary-Color-SiteView);
    color: red;
  }
  