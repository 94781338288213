.container {
  position: relative;
}

.input {
  border: 1px solid rgb(196, 196, 196);
  border-radius: 10px;
  width: 270px;
  height: fit-content;
  padding: 10px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 14px;
}

.input::placeholder {
  color: rgb(67, 67, 67);
}

.input:focus {
  outline: rgb(20, 22, 52);
}

.clearButton {
  position: absolute;
  /* left: 10px; */
  top: 8px;
  right: 10px;
  opacity: 50%;
  cursor: pointer;
  font-size: 18px;
}

.clearButton:hover {
  /* background-color: red;
  border-radius: 50%;
  width: fit-content;
  height: fit-content; */
  /* padding: 10px; */
}