.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
    padding: 20px;
    max-width: 100%;
    height: 530px;
    width: clamp(400px, 520px, 700px);
  
}

.Title {
    font-size: var(--font-size-Title);
    font-weight: 500;
    margin: 0px;
    color: rgb(73, 73, 73);
}

.formContainer{
    width: 80%;
    height: fit-content;
}

.subInfo {
    display: block;
    word-wrap: break-word;
    width: 100%;
    margin: 0px;
    text-align: center;
    font-size: var(--font-size-normal);
    font-weight: 400;
    color: rgb(73, 73, 73);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    /* margin: 22px; */
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 20px;
    margin: 0;
}

.serviceLink {
    display: flex;
    /* margin: 5px; */
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
    margin-top: 10px;
    font-size: var(--font-size-normal);
}

.serviceLink:hover {
    color: rgb(175, 175, 175);
    cursor: pointer;   
}

.serviceLink:active{
    color: rgb(206, 206, 206);
    cursor: pointer;   
}

.extraLinks-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap:10px
    /* margin-top: 10px; */
}

.extraLink {
    margin: 0px;
    color: rgb(20, 22, 52);
    font-size: var(--font-size-normal);
    font-weight: 400;

}

.listItem{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.link {
    color: rgb(20, 22, 52);
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    text-decoration: none;
}

.link:hover{
    color: rgb(175, 175, 175);
    cursor: pointer;   
}

.link:active{
    color: rgb(206, 206, 206);
    cursor: pointer;   
}


