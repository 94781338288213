.OTPCodeContainer {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.copyButtonContainer {
    display: flex;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: flex-end;
}

.pincodeTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
}
.pincodeTextContainer h1 {
    font-size:  50px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 10px;
}