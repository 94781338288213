.Dashboard-container {
  /* This controls the width and height of the SearchCard */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* align-items: center; */
  background: rgba(202, 51, 51, 0);

  /* Size of searchCard*/
  height: 100%;

  width: 100%;

  gap: 30px;
}



/* For styling the Users/Online/Offline from all device comp */
.stats-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 35px;
}

.Search-container{
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3px;
}

/* for the Search filter Headering */
.headers {
  margin: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  
}

.header-data {
 margin-top: 20px;
}

.header-data h2 {
 text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  height: 40px;

}


.header-data h2:hover {
  border-bottom: 2px solid black;
}


.header-data_h2Active{
  border-bottom: 2px solid black;
}



.searchList-Results {
  

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  max-height: 300px;
  width: 240px;
  max-width: 1500px;
  color: rgb(0, 0, 0);
  /* padding: 20px 0 20px 0; */
  
  /* background-color: rgb(255, 255, 255); */
  background-color: #e6e4e4;
  overflow-y: auto;
  border-radius: 0;

}

.searchList-Results h2 {
  margin: 0;
  padding: 2px;
  padding-left: 10px;
}

::-webkit-scrollbar {
  width: 0em;
}


.list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 0 12px 0 12px;
  color: black;
  height: 500px;
  max-height: 300px;
  width: 100%;
  /* max-width: 900px; */


}

.list-item h3:first-of-type {
  /* border-left: 1px solid hsl(0, 0%, 0%); */
  padding-left: 10px;
  /* background-color: aqua; */
  /* width: 100px; */
}


.list-item h3 {
  /* border-right: 1px solid hsl(0, 0%, 0%); */
  padding-right: 10px;
  width: 220px;

}

.list-item:hover {
  background-color: rgba(49, 184, 119, 0.573);
  color: rgb(255, 255, 255);

}

.list-item:active {
  transform: scale(1.1);
  padding: 0 10px 0 10px;
  border-color: 1px solid hsl(0, 0%, 0%);

}

.list-item h3:active {

  border-color: 1px solid hsl(0, 0%, 0%);

}

@media screen and (max-width: 1320px) {

  .SearchCard {

    margin-left: 0px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 1173px) {

  .SearchCard {
    width: 100%;
  
  }
}

@media screen and (max-width: 900px) {

  .SearchCard {
    width: 100%;    
  }
}

@media screen and (max-width: 740px) {

  .stats-container {
    
    flex-direction: column;
    align-items: center;
    gap: 22px;
  }
}

