.input {
    background: blue;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.blue {
    background: red;
}

.black {
    background: #575967;

}

.grey {
    background: grey;
}

.pinContent {
    display: flex;
    color: rgba(255, 255, 255, 0.542);
    align-items: center;
    justify-content: center;
    margin: 0px;
    font-size: 17px;
    text-decoration: none;
}

@media screen and (max-width: 1500px) {
    .input {
        width: 18px;
        height: 18px; 
        border-radius: 50%;
    }
}