.dropDown-Icon {
    position: relative;
    width: auto;
    height: 30px;
    margin: 0;
    filter: invert(80%);
}

.container {
    position: absolute;
    border: 1px solid #ccc;
    background: var(--Primary-Color);
    border-radius: 8px;
    /* padding: 15px; */
    min-width: 300px;
    /* max-width: 424px; */
    /* height: auto; */
    max-height: 316px;
    z-index: 1000;
    right: 30px;
    top: 105px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.header-container {
    width: 100%;
    height: fit-content;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: var(--Primary-Color);
    padding: 15px;
    padding-bottom: 2px;
    border-radius: 6px 6px 0px 0px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.clearNotifications-button{
    filter: invert(70%);
    width: 25px;
    height: 25px;
}

.clearNotifications-button:hover{
    filter: invert(90%);
}

.button-container{
    padding: 15px;
}

.bell-container{
    display: flex;
    align-items: center;
    /* gap: 5px; */
    position: relative;
}

.container .dropDown-title {
    text-align: left;
    font-weight: 400;
    margin: 0;
    color: white;
    border-bottom: 1px solid rgb(255, 255, 255);
    padding: 5px 0px;
    width: fit-content;
}

.totalNotifications{
    position: absolute;
    left: 20px;
    top: -10px;
    color: white;
    margin: 0px;
    background-color: var(--Primary-Color); 
    border: 1px solid white;
    /* min-width: 40px; */
    width: 26px;
    height: 26px;
    border-radius: 50%;
    /* padding: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;

}


.list-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    border-radius: 12px;
    padding: 8px 5px;
    width: fit-content;
}

.list {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* padding: 10px; */
    /* padding-top: 0px; */
    margin-top: 15px;
    overflow-y: scroll;
    max-height: 240px;
    width: 90%;
    align-items: center;
}


/* 
/* For WebKit browsers */
.list::-webkit-scrollbar {
    width: 6px;
    /* padding-right: 10px; */
}

.list::-webkit-scrollbar-thumb {
    background-color: rgb(174, 174, 174);
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Border radius of the scrollbar thumb */
}

.list:-webkit-scrollbar-track {
    background-color: rgb(78, 78, 78);
    /* Color of the scrollbar track */
    border-radius: 10px;
    /* Border radius of the scrollbar track */
}

.dropDown-Innertext {
    text-align: center;
    padding: 5px;
    color: white;
    font-weight: 400;
}

/* .container ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 30px;
    margin: 0px;
    padding: 0px;
    gap: 12px;
} */

.DropdownItem-container {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
}



.img {
    width: 20px;
    height: 20px;
    margin: 0;
    filter: grayscale(100%);
    background-color: transparent;
}


/* .DropdownItem-container h2 {
    margin: 0px;
    padding: 5px 10px;
    font-size: 18px;
    color: grey;
    cursor: pointer;
}

.DropdownItem-container h2:hover {
    color: rgb(0, 0, 0);
    background-color: rgb(216, 218, 218);
    border-radius: 12px;

}

.DropdownItem-container h2:active {
    color: rgb(118, 118, 118);
    background-color: rgb(211, 211, 211);
    border-radius: 12px;

}

.dropDown-Icon:hover {
    filter: invert(50%);
}

.totalNotifications:hover{
    color: #959595;
    border-color: #ccc;
}

.dropDown-Icon:hover + .totalNotifications {
    
    color: #ccc;
    border-color: #ccc;
}

.totalNotifications:hover ~ .dropDown-Icon {
    
    filter: invert(50%);
} */
