.statsContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 35px;
  }

  .container {
    width: 100%;
    height: fit-content;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust 150px as needed */
}

.searchContainer{
  margin-left: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}

  