.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin: 22px; */
    padding: 22px;
}

.Title {
    font-size: var(--font-Main-Title);
    font-weight: 500;
    margin: 20px;
    margin-top: 0px;
    color: rgb(73, 73, 73);
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 20px;
    margin: 0;
}

.serviceLink {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
}

.extraLinks-list {
    list-style: none;
    padding: 0;
}

.extraLinks-list li {
    display: flex;
}

.extraLink {
    margin: 0px 15px;
    color: rgb(20, 22, 52);
    font-size: 16px;

}

.extraLink span {
    color: rgb(52, 20, 46);
    font-size: 16px;
    font-weight: 600;
}


.sectionTitle {
    background: rgb(255, 255, 255);
    width: fit-content;
    padding: 6px;
    font-style: italic;
    font-size: 18px;

    margin-top: 165px;
    margin-left: 100px;

    position: absolute;
    top: 0;
    left: 0;
}

.sectionTitle2 {
    background: rgb(255, 255, 255);
    width: fit-content;
    padding: 6px;
    font-style: italic;
    font-size: 18px;

    margin-top: 563px;
    margin-left: 100px;

    position: absolute;
    top: 0;
    left: 0;



}

.input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    height: fit-content;
    padding: 18px 10px;
    /* background-color: rgb(218, 218, 218); */
    border: 1px solid rgb(196, 196, 196);
    border-radius: 12px;
    gap: 10px;
    position: relative;
}

.input-container .subTitle {
    position: absolute;
    background-color: white;
    color: #484848;
    padding: 5px;
    top: -15px;
    left: 22px;
}

.toggle-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.submitButton {
    border-radius: 12px;
    border: none;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    align-self: flex-end;
    background-color: var(--Primary-Color);
    color: white;
    border: 1px solid var(--Primary-Color);
    margin-top: 10px;
}

.submitButton:hover {
    background-color: #63d263;
}

.submitButton:active {
    scale: 0.97;
}


.activeSwitchType {
    background-color: var(--Primary-Color);
    color: white;
}

@media screen and (max-width: 2100px) {
    .container {
        width: 434px;
    }
}