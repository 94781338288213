.SideNav-item {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* width: 20px; */
    height: 40px;

    gap: 10px;

    /* background-color: rgb(204, 12, 12); */
    padding: 22px;
    border-radius: 12px;
    color: rgb(0, 0, 0);
    transition: height 0.8s ease;
    
    min-width: 175px;
    width: 85%;
    min-width: fit-content;
}

.SideNav-item h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    text-align: left;
    /* word-wrap: break-word; */
    color:#818181;
    width: 100%;
}


.SideNav-item .img {
    height: 25px;
    width: 25px;
}

.itemName {
    text-decoration: none;
    color: inherit;
}

.SideNav-item:hover ,
.SideNav-item:focus ,
.SideNav-item.active  {
    background-color: var(--Primary-Color);
    border-radius: 0px 12px 12px 0px;
}

.SideNav-item:hover h3,
.SideNav-item:focus h3,
.SideNav-item.active h3 {
    /* font-weight: 900; */
    color: rgb(255, 255, 255);
    /* Your other styles for the h3 element when .SideNav-item is hovered, focused, or active */
}

.SideNav-item:hover img,
.SideNav-item.active .img {
    filter: brightness(0) saturate(100%) invert(100%);
}


@media screen and (max-width: 1500px) {
    .SideNav-item {
        width: 15px;
    }

    .SideNav-item h3 {
        font-size: 12px;
        /* word-wrap: break-word; */
        white-space: nowrap;
    }

}

@media screen and (max-width: 490px) {
    .SideNav-item .img {
        height: 32px;
        width: 32px;
    }
}

.active{
    
    border: solid white;
    border-left: none;
    
}