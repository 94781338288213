.infoCard-container h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
}

.infoCard-container h2:nth-child(2) {
  color: rgb(93, 93, 93);
  font-weight: 800;

}

.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding-left: 20px;
}

.svg {
  transform: rotate(180deg);
}
