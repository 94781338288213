.layout {
    display: flex;
    width:  475px;
    height: auto;
}

.pin-output {
    display: flex;
    flex-direction: row;


    width: 100%;
    height: 80px;
    /* background-image: linear-gradient(to right,#404251 , #374b53); */
    background-color: rgb(26, 16, 55);
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    justify-content: center;
}



.img {
    transform: scale(.6);
}
