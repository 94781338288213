.siteView {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: top;

    /* padding: 12px; */
    width: 100%;
    height: 100%;

    border-radius: 0 0 12px 12px;
    /* background-color: #383838fd; */
    gap: 24px;

    /* overflow-y: hidden; */
}

.breadcrumb {
    margin: 10px 0px;
}

.mainView {
    display: flex;
    gap: 20px;
    width: 100%;
    padding-bottom: 30px;
    height: 100%;
    /* height: 1344px; */
}


.left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    gap: 30px;
    width: auto;
    /* width: 100%; */
    max-width: 40%;
    height: 100%;
    /* background-color: #38383800; */
}

.right {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    gap: 16px;
    width: 100%;
    height: fit-content;
    /* max-height: 974px; */
    /* background-color:  #38383800; */
}




.table {
    height: 94.3%;
}

/* 
.list-items ul {
    width: 100%;
    height: 100%;
    background-color: rgb(208, 208, 208);
    border: 2px solid rgb(229, 229, 229);
}

.list-items ul li {
    width: 80%;
    height: auto;
    background-color: #989898
} */

.settingIconimg {
    width: 20px;
    height: 20px;
    align-self: center;
    justify-self: center;
    padding: 0px;
}

/* Drop down menu styling from cog wheel in table */
.container {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff;
    padding: 8px;
    z-index: 1000;
    right: 20px
}

.container h3 {
    text-align: left;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    margin-left: 15px;

}

.list-container {
    display: flex;

    background-color: rgb(237, 237, 237);
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.container ul {
    list-style: none;
    padding-left: 30px;
    margin: 0px;
    padding: 0px;
}

.Search-container {
    align-items: flex-start;
}

.table-container {


    background-color: white;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-items: center;
    /* padding: 12px; */
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 12px;
}


/* Role column cell styling */

.roleCell_container {

    width: fit-content;
    border-radius: 12px;
    padding: 4px 5px;

}

.VerifiedCell_image {

    height: 30%;

    width: clamp(20px, 50%, 50px);
    height: 30%;
}

.Icon {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    transition: transform 0.3s;
  }
  

@media screen and (max-width: 1500px) {

    .siteView {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: top;
    
        /* padding: 12px; */
        width: 100%;
        height: 100%;
    
        border-radius: 0 0 12px 12px;
        /* background-color: #383838fd; */
        gap: 24px;
       
    
        /* overflow-y: hidden; */
    }
    
    .mainView {
        gap: 10px;
        width: 100%;
        padding-bottom: 30px;
        height: 100%;
       

    }

    .left {
       
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        gap: 30px;
        width: auto;
        /* width: 100%; */
    }

    .right {
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        gap: 16px;
        width: 0%;
        height: fit-content;
        /* max-height: 974px; */
       
    }

    .table-container {
        width: fit-content
    }

    .loadingSpinner-container {
        position: fixed; /* Ensures it stays above content and on the viewport */
        top: 50%; /* Center vertically */
        right: 30%; /* Offset to the right by 50% */
        transform: translate(50%, -50%); /* Adjusts the spinner to be visually centered */
        width: auto; /* Adjust to the spinner size */
        height: auto; /* Adjust to the spinner size */
        z-index: 1000; /* Ensure it’s above other content */
        display: flex;
        align-items: center;
        justify-content: center;
    }

}



.inviteUserForm-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}