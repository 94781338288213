.container {
    display: flex;
    gap: 10px;
    background-color: var(--Primary-Color);
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: white;
    min-width: 300px;
    padding-right: 0;
}

.container h3 {
    font-weight: 400;
}


.buttonContainer {
    display: flex;
    gap: 5px;
}

.activeButton{
    background-color: rgb(0, 0, 0);
    border: 1px solid rgb(255, 255, 255);
    color: white;
}