.searchBar {
  border-radius: 8px;
  border: 0px;
  

  /* min-height: clamp(50px, 60px, 70px); */
  height: 50px;
  font-size: 18px;
  padding-left: 25px;
  /* Adjust the size of the icon */
  /* background-image: url('../../../assets/PortalIconnographySVGs/PortalAppRealted_Icons/Power Search/__Search.svg'); */
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: 10px center;
  border: 1px solid rgb(196, 196, 196);
}

.searchBar:focus {
  outline: 1px solid rgb(196, 196, 196);
}


/* Override the styles from the media query */
.searchBar {
  width: 100%;
  /* or any other value for smaller screens */
  max-width: 735px;
  min-width: 200px;
}

/* @media screen and (min-width: 1700px) {
  .searchBar {
    min-width: 1120px;
  }

} */