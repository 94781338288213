.StatusIndicatorCard {
    width: 120px;
    height: 30px;

    background-color: #bababa;
    border-radius: var(--button-border-radius);
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--font-normal);
    font-weight: 900;
    background: #777777;
}

.inactive {
    /* Styles for inactive state */
    background-color: var(--Inactive-color);
}

.partiallyActive {
    /* Styles for partially active state */
   
    background: rgb(255, 132, 0);
    
}

.active {
    /* Styles for active state */
    background-color: var(--Active-color);
}


@media screen and (max-width: 600px) {
    .StatusIndicatorCard {
        width: fit-content;
        padding: 6px
    }

}