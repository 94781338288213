.input {
    background-color: rgb(202, 202, 202);
    border: none;
    border-radius: 10px;
    width: 100%;
    height: fit-content;
    padding: 15px;
    padding-left: 20px;
    font-weight: 400;
    font-size: var(--font-size-normal);    
}

.input::placeholder {
    color: rgb(67, 67, 67);
}

.input:focus {
    outline: rgb(20, 22, 52);
}