.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
    padding: 20px;
    max-width: 100%;
    height: 509.63px;
    width: clamp(400px, 520px, 700px);
    /* padding-top: 15px; */
    gap: 20px;
}

.extraLinks-list {
    list-style: none;
    padding: 0;
    /* margin-top: 10px; */
}

.extraLink {
    margin: 0px;
    color: rgb(20, 22, 52);
    font-size: var(--font-size-normal);
    font-weight: 400;

}

.listItem{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.link {
    color: rgb(20, 22, 52);
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    text-decoration: none;
}

.link:hover{
    color: rgb(175, 175, 175);
    cursor: pointer;   
}

.link:active{
    color: rgb(206, 206, 206);
    cursor: pointer;   
}