.card{
    /* min-height: 177px; */
  }
  .grid-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 800px;
    /* background-color: rgb(254, 254, 254); */

    border-radius: 12px;
    padding: 0px 0px 12px 0px;
  }

  .ModalIcon {
    width: 100px;
    height: 100px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
  }
  
  
  .grid-ul {
    width: 100%;
    /* padding: 10px; */
    display: grid;

    /* column-gap:50px; */
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* grid-template-columns: 450px 450px 450px 450px 450px; */
    /* grid-template-columns: repeat(5, auto); */
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-template-columns: repeat(4, minmax(407px, 0fr)); */
    row-gap: 15px;
    /* padding-right: 20px; */
    /* padding-bottom: 20px; */
    /* overflow: auto; */
  }
  
  .grid-li {
    display: flex;
    width: fit-content;
  }
  
  
  /* @media screen and (max-width: 1500px) {
    .grid-ul {
    
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (max-width: 1700px) {
    .grid-ul {
    
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 1205px) {
    .grid-ul {
    
      grid-template-columns: repeat(1, 1fr);
    }
  } */
  
  
  @media screen and (max-width: 873px) {
  
  
    /* .grid-ul {
      background-color: aquamarine;
      width: fit-content;
    }
  
    .grid-li {
      justify-content: center;
    } */
  
    .grid-ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
    }
  }