.tableButton-container {
  width: fit-content;
  height: 37px;
  background-color: #cdcdcd;
  border-radius: 10px;
  border: none;

  font-size: 14px;

  color: rgb(72, 72, 72);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px 10px;
  cursor: pointer;
  justify-content: center;
}

.image {
  width: 20px;
  height: auto;
  object-fit: contain;
}

.tableButton-container:hover  {
  background-color: rgb(46, 46, 46);
  color: white;
}

.tableButton-container:hover .image  {
  filter: invert(100%);
}



@media screen and (max-width: 800px) {

  .tableButton-container {
      width: auto;
      height: auto;
      background-color: rgb(255, 255, 255);
      font-size: 14px;
      white-space: nowrap;
  }

}
