.card {
    background-color: #ffffff;
    border-radius: 8px;
    width: 690px;
    height: fit-content;
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    

}

.Title {
    font-size: 55px;
    font-weight: 500;
    margin: 20px;
    margin-top: 0px;
    color: rgb(73, 73, 73);
}

.subInfo {
    display: block;
    word-wrap: break-word;
    width: 50%;
    margin: 0px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: rgb(73, 73, 73);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 22px;
}   

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 30px;
    margin: 0;
}

.inputs-list .input {
    background-color: rgb(202, 202, 202);
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 70px;
    padding-left: 20px;
    font-weight: 400;
    font-size: 22px;

}

.inputs-list .input::placeholder {
    color: rgb(67, 67, 67);
}

.inputs-list .input:focus {
    outline: rgb(20, 22, 52);
}

.serviceLink {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
}

.extraLinks-list{
    list-style: none;
    padding: 0;
}

.extraLink{
    margin: 25px;
    color: rgb(20, 22, 52);
    font-size: 16px;
  
}

.extraLink span{
    
    color: rgb(20, 22, 52);
    font-size: 16px;
    font-weight: 600;
}



