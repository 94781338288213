.lineGraph-container {
    width: 100%;
    /* max-width: 1715px; */
    height: 300px;
    /* padding: 40px  10px; */
    /* border: 1px solid #de1a1a; */
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    background-color: rgba(250, 235, 215, 0);
}

.lineGraph-container canvas{
    max-width: 100%;
}
