.card{
    /* min-height: 177px; */
  }

  .table {
    width: 100%;
    table-layout: auto;
    height: 100%;
    /* padding-bottom: 20px; */
    border-radius: 12px;
    box-shadow: 0px 0px 3px rgba(196, 196, 196);
}

.ModalIcon {
  width: 100px;
  height: 100px;
  /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
  background-repeat: no-repeat;
  /* Set the transition duration */
  transition: transform 0.3s;
}


.card2 {
  min-width: 1500px;
  width: 100%;
  height: 100%;
}

.tableCardLayoutUI-wrapper {
  overflow-x: auto; /* Enables horizontal scrolling if content overflows */
   max-width: 100%; /* Prevents content from exceeding the container */
   display: block; 
     
     /* padding: 20px 30px; */
 }

  .grid-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 800px;
    /* background-color: rgb(254, 254, 254); */
    border-radius: 12px;
    padding: 12px 0px 12px 0px;
  }
  
  
  .grid-ul {
    width: 100%;
    /* padding: 10px; */
    display: grid;
  
    /* column-gap:50px; */
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* grid-template-columns: 450px 450px 450px 450px 450px; */
    /* grid-template-columns: repeat(5, auto); */
    /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    row-gap: 15px;
    /* padding-right: 20px; */
    /* padding-bottom: 20px; */
    /* overflow: auto; */
  }
  
  .grid-li {
    display: flex;
    width: fit-content;
  }

  .top-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: aqua; */
    /* gap: 5px; */
    padding: 5px 5px
}
  
  
  /* @media screen and (max-width: 1500px) {
    .grid-ul {
    
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (max-width: 1700px) {
    .grid-ul {
    
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 1205px) {
    .grid-ul {
    
      grid-template-columns: repeat(1, 1fr);
    }
  } */

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-container {
    /* height: 100%; */
    max-height: 700px;
    width: 100%;
    overflow-y: scroll;
    /* padding-bottom: 25px; */
    /* background-color: red; */
    border-radius: 12px;

}

  .tableTitleText{
    font-size: 15px;
    display: flex;
    color: #7f7f7f;
    cursor: pointer;
  }

  .tableValueTextBold{
    cursor: pointer;
    width: fit-content;
    font-weight: 500;
    font-size: 15px;
}

.tableValueText{
  cursor: pointer;
  width: fit-content;
  font-size: 15px;
}

.PillHeight{
  height: fit-content;
  font-size: 15px;
}

.dropDown-Icon {
  position: relative;
  width: 25px;
  height: 25px;
  margin: 0;
  filter: grayscale(100%);
  /* filter: grayscale(100%); */

}

.Icon {
width: 100px;
height: 100px;
/* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
background-repeat: no-repeat;
/* Set the transition duration */
transition: transform 0.3s;
}

.EyeSpyFX {
  background: rgb(100, 149, 237);
}

.RESURE {
  background: rgb(75, 0, 130);
}

.PARFAS {
  background: rgb(0, 0, 0);
}

.PORTAL_SECURITY {
  background: rgb(0, 0, 139);
}

.SOLO {
  background: rgb(178, 34, 34);
}

.KNOWIO{
  background: rgb(20, 155, 8);
}

.CPS{
  background: rgb(8, 155, 121);
}

.CSC_SECURITY{
  background: rgb(8, 150, 155);
}
.EES{
  background: rgb(94, 8, 155);
}

.defaultCompany {
  background: rgb(110, 110, 110);
}

.searchContainer{
  margin-left: 10px;
  
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}
  
  
  @media screen and (max-width: 873px) {
  
  
    /* .grid-ul {
      background-color: aquamarine;
      width: fit-content;
    }
  
    .grid-li {
      justify-content: center;
    } */
  
    .grid-ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
    }
  }

  @media screen and (max-width: 1500px) {

    .tableTitleText{
      font-size: 13px;
      display: flex;
      color: #7f7f7f;
      cursor: pointer;
    }

    .tableValueText{
      cursor: pointer;
      width: fit-content;
      
      font-size: 13px;
    }

    .tableValueTextBold{
      cursor: pointer;
      width: fit-content;
      font-weight: 500;
      font-size: 13px;
    }

    .PillHeight{
      height: fit-content;
      font-size: 12px;
    }

    .bottom-wrapper_divider {
      background-color: rgb(127, 127, 127);
  
      height: 37px;
      width: 1px;
  
      margin-left: 10px;
      margin-right: 10px;
  }
}