
.text {
    font-weight: 500;
}

.paginationButtons-container {
    display: flex;
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    /* margin: 10px 20px; */
    padding: 22px  0px 22px 0;
    gap: 10px;
}

.paginationButtons-container h4 {
    color: #0f0f0f;
    margin: 0px;
    font-size: var(--font-normal);
    font-weight: 300;
}

.pagination_button {
    display: flex;
    /* Button Size */
    width: 40px;
    height: var(--button-height);
    align-items: center;
    justify-content: center;
    background-color: unset;
    border: 0px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

/* Prev & Next Table Page Button Disabled Styling */
.pagination_button:disabled,
.pagination_button:disabled:hover {
    background-color: rgba(196, 196, 196, 0);
    cursor: not-allowed;
}


.button_image {
    width: 100%;
    height: 100%;
    border: 0px;
}

/* When focusing on the button the button, the outline colour will change*/
.pagination_button:focus {
    outline: 1px solid #545454;
}

.currentPage-box {
    background-color: rgba(255, 0, 0, 0);
    height: var(--button-height);
    width: 35px;
    border-radius: var(--button-border-radius);
    text-align: center;
    border: 1px solid #C4C4C4;
    font-size: var(--font-normal);
}

.currentPage-box[type="number"]::-webkit-inner-spin-button,
.currentPage-box[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.currentPage-box[type="number"] {
    -moz-appearance: textfield;
}

.dropdown {
    display: block;
    border: unset;
}

.dropdown.open{
    display: block;
}

.dropdown {
    /* display: none; */
    border: unset;
}

.pageSize-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C4C4C4;
    height: 37px;
    width: 100px;
    border-radius: 10px;
    padding: 0px 10px;
    position: relative;
    cursor: pointer;
    
}

.pageSize-container label{
    font-size: var(--font-normal);
    
}


.dropdown {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    background-color: rgba(196, 196, 196, 0);
}

.pageSize-container:hover .dropdown{
    cursor: pointer;
}

.selected-option {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    margin-left: 8px;
}

.arrow.up {
    transform: rotate(180deg);
}

.options {
    display: none;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #C4C4C4;
    top: 100%; /* Adjust this value as needed */
    border-radius: 0 0 5px 5px;
    position: absolute;
    top: 100%;
}



.options li {
    padding: 10px;
    cursor: pointer;
}

.options li:hover {
    background-color: #f1f1f1;
}

.total-results{
    font-weight: 300;
    font-size: 16px;
}



