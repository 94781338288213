.form-button {
    border: none;
    border-radius: 10px;
    background-color: rgb(28, 29, 50);
    color: white;
    font-size: var(--font-size-normal);
    font-weight: 600;
    padding: 15px;
    width: 100%;
    height: fit-content;
    margin-top: 25px;
}

.form-button:hover {
    background-color: rgb(175, 175, 175);
    color: rgb(255, 255, 255);
}

.form-button:active {
    background-color: rgb(175, 175, 175);
    color: rgb(68, 67, 67);
}

/* @media screen and (max-width: 1700px) {
    .form-button {
        font-size: 18px;
        padding: 18px;
    }

} */