.timeDatePicker-Container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 10px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: center;
}



.icon {
    height: 100px;
    width: 100px;
}


.nextButton {}


.statePicker-Container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 10px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: center;
}

.statePicker-Container h3 {
    font-weight: 400;
}



.buttonContainer{
    display: flex;

    gap: 20px;
}

.input {
    border: 1px solid rgb(196, 196, 196);
    border-radius: 10px;
    width: 480px;
    height: fit-content;
    padding: 10px;
    padding-left: 20px;
    font-weight: 400;
    font-size: 14px;
  }