/* Your existing styles */

.modalCard {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 0px;
    min-width: 700px;
    width: fit-content;
    height: fit-content;
    border: none;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    box-shadow: 0px 1px 20px;
    animation: fadeInFromTop 0.5s ease-out forwards;
    overflow: visible;
}

@keyframes fadeInFromTop {
    from {
        transform: translateY(-60px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.MacHeader {
    width: 100%;
    min-height: 50px;
    /* background-color: var(--Primary-Color); */
    /* padding: 10px; */
    padding: 10px 10px 0px 25px;
    padding-left: 25px;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    position: relative;
    align-items: center;
}

.WindowsHeader {
    width: 100%;
    min-height: 50px;
    /* background-color: var(--Primary-Color); */
    /* padding: 10px; */
    padding: 10px 10px 0px 25px;
    padding-left: 25px;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    position: relative;
}

.Title {
    color: #858585;
    margin: 0px;
    min-width: 500px;
    word-wrap: none;
    margin-bottom: 10px;

}

.headerButtonsLayout{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.headerTabs{
    display: flex;
    gap: 10px;
    height: 100%;
    
}

.tabButton{
    border-radius: 12px 12px 0 0;
    padding: 10px 20px;
    /* position: absolute; */
    /* background-color: var(--Primary-Color); */
    color: #c9c9c9;
    border: 1px solid white;
    border-bottom: 0px;
}
.tabButton:hover{
    background-color: rgb(255, 255, 255);
    color: #000000;
    border: 1px solid rgb(0, 0, 0);
    border-bottom: 0px;
    cursor: pointer;
}

.tabButtonActive{
    background-color: rgb(255, 255, 255);
    color: #000000;
}

.closeButton {
    border: none;
    border-radius: 6px;
    min-height: 25px;
    min-width: 30px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 10px; 
    font-size: 12px;
}

.MacCloseButton{
    aspect-ratio: 1;
    border-radius: 50%;
    display: grid;
    place-items: center;

    min-height: 18px;
    min-width: 22px;
    margin-top: 2px;
}

.closeButton:hover {
    background-color: rgb(238, 5, 5);
    cursor: pointer;
}

.closeButton:active {
    background-color: rgb(255, 0, 0);
    transform: scale(.9);
}

.modal-layout {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    background-color: var(--Background-Secondary);
    border-radius: 0px 0px 8px 8px;
    padding: 20px;
    gap: 20px;
    overflow: none;
}

.top {
    display: flex;
    width: 100%;
    height: 350px;
    gap: 20px;
    margin-top: 15px;
}

