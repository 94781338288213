.StatusIndicatorCard{
    width: fit-content;
    height: 27px;

    background-color: #1585b1;
    border-radius: 8px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--font-normal);

    padding: 5px 10px;
    

}