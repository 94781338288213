.GroupCardContentLayout {
  width: 100%;
  height: 100%;
  padding: 25px 25px;
  padding-top: 10px;
  border-radius: 12px;
  text-decoration: none;
  align-items: center;
  align-content: center;
  gap: 8px;
  cursor: default;
  position: relative;
  /* background-color: white; */
}

.header-bar {
  color: rgb(22, 138, 173);
}

.top-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
}

.settingsIcon {
  position: absolute; /* Position the icon absolutely within the container */
  top: 13px; /* Adjust the vertical position */
  right: 28px; /* Adjust the horizontal position */
  width: 25px; /* Set the width for the icon */
  height: 25px; /* Set the height for the icon */
  cursor: pointer; /* Indicate that it is clickable */
}

.settingsDropdown {
  position: absolute; /* Ensures the dropdown stays inside the card */
  top: 10px;
  right: 10px;
  z-index: 1000; /* Ensure it appears above other elements */
}

.middle-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
}

.siteDetails-layout {
  width: 100%;
  height: 100%;
  display: flex; /* Added to make it a flexbox container */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  white-space: nowrap;
  gap: 5px;
}

.siteDetails-layout h3 {
  margin: 3px;
  font-size: 20px;
  color: rgb(90, 90, 90);
  text-align: center; /* Ensures text is centered within h3 */
  width: 100%;
  font-weight: 500; /* Ensures h3 takes full width of its container */
}

.icons-container {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  position: absolute;
  left: 88%;
  top: 0%;
  gap: 1px;
  width: fit-content;
  /* background-color: black; */
}

.userCount {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 15px;
}

.userCount h3 {
  margin: 3px;
  font-size: 18px;
  color: rgb(162, 162, 162);
  text-align: start; /* Ensures text is centered within h3 */
  width: fit-content;
  font-weight: 500; 
}

.tagName {
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
}

.indicatorsCardsContainer {
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

.Link {
  all: unset;
  cursor: pointer;
  margin-top: 5px;
}

/* .arrowButton:hover img {
  cursor: pointer;
  transform: translateX(10px);
} */

.arrowButton {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.Link:hover .arrowButton {
  cursor: pointer;
  transform: translateX(10px);
  transition: transform 0.3s;
}

.middle-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
}

.bottom-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 10px;
}

.bottomLeft-wrapper {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  gap: 10px;
}

.userIcons {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}


.userIcon {
  background-color: var(--Primary-Colour);
}

.outputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
  row-gap: 5px
}

.inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
  row-gap: 5px;
}

.bottom-wrapper_divider {
  background-color: rgb(127, 127, 127);
  height: 47px;
  width: 1px;
  margin-left: 20px;
  margin-right: 20px;
}

.Icon {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  transition: transform 0.3s;
  cursor: pointer;
}

.indicatorsCards-container {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  align-items: center;
}

.skeleton-loader {
  background-color: #e0e0e0;
  /* border-radius: 4px; */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.skeleton-loader::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, #e0e0e0 0%, #f0f0f0 50%, #e0e0e0 100%);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  from {
      left: -150px;
  }

  to {
      left: 100%;
  }
}

.EyeSpyFX {
  background: rgb(100, 149, 237);
}

.RESURE {
  background: rgb(75, 0, 130);
}

.PARFAS {
  background: rgb(0, 0, 0);
}

.PORTAL_SECURITY {
  background: rgb(0, 0, 139);
}

.SOLO {
  background: rgb(178, 34, 34);
}

.KNOWIO{
  background: rgb(20, 155, 8);
}

.CPS{
  background: rgb(8, 155, 121);
}

.CSC_SECURITY{
  background: rgb(8, 150, 155);
}
.EES{
  background: rgb(94, 8, 155);
}

.defaultCompany {
  background: rgb(110, 110, 110);
}