.pin-output {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    /* background-image: linear-gradient(to right,#404251 , #374b53); */
    background-color: rgb(26, 16, 55);
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    justify-content: center;
}



.img {
    transform: scale(.3);
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;
}

.text-wrapper h2,
h3 {
    margin: 0;
    text-align: left;
    align-items: flex-start;
}

.pinLable {
    display: flex;
    flex-direction: row;
    align-items: left;

}


.pin-output:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.pin-output:active {
    cursor: pointer;
    transform: scale(1);
    color: rgb(183, 183, 183);
}



