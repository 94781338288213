.form {
    display: flex;
    gap: 20px;
    width: 100%;
    min-width: 100px;
    max-width: 700px;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    flex-wrap: wrap;
}

.form button {
    width: fit-content;
}

.filters {
    display: flex;
    
    justify-content: flex-end;
    width: fit-content;
    gap: 20px;
}