.breadcrumb-container {
    all: unset;
    display: flex;
    gap: 5px;
    background-color: #ffffff00;
    border: 0px;
    overflow: none;
}

.breadcrumb-ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
}


.button-container {
    /* all: unset; */
    color: var(--Primary-Color);
    text-decoration: none;
    cursor: pointer;
}

.button-container:hover {
    /* background-color: var(--Primary-Color); */
    color: rgb(201, 201, 201);
}

.button-container:active,
.button-container.active {
    all: unset;
    color: black;
    font-weight: 500;

}


.breadcrumbs {
    max-width: 1200px;

}

.breadcrumbs>* {
    display: inline-block;
    margin-right: 10px;
}

.breadcrumbs .crumb::after {
    content: '>';
    margin-left: 10px;
}

.breadcrumbs .crumb:last-child:after {
    display: none;
}

