@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  color: #000000;
}

h3 {
  padding: 0;
}

h4 {
  margin-bottom: 3px;
}

@media screen and (max-width: 1500px) {
  body {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(255, 255, 255);
    color: #000000;
  }
  
  
}