


.input {
    border-radius: 6px;
    width: 100px;
    height: 40px;
    border: 1px solid black;
    text-align: center;
    font-size: 20px;
}