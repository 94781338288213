.ulist {
  /* margin: 2rem auto; */
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.list{
  width: 100%;
  height: 100%;
}

/* Media query for screens with a maximum width of 600 pixels */
@media screen and (max-width: 390px) {
  .header {
    /* font-size: 14px; */
    padding: 0% 5%;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  /* Additional styles for smaller screens */
}