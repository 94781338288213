/*  https://codepen.io/manovotny/pen/poLKJg  */
.battery {
    border: 3px solid #333;
    width: 28px;
    /* Adjust the width to make it horizontal */
    height: 18px;
    /* Adjust the height to make it horizontal */
    padding: 2px;
    border-radius: 4px;
    position: relative;
    /* margin: 15px 0; */
  
    &:after {
      content: '';
      height: 12px;
      /* Adjust the height to make it horizontal */
      width: 3px;
      /* Adjust the width to make it horizontal */
      background: #333;
      display: block;
      position: absolute;
      top: -0.2px;
      /* Adjust the position to make it horizontal */
      right: -5px;
      /* Adjust the position to make it horizontal */
      border-radius: 0 2px 2px 0;
    }
  
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid #fff;
      border-radius: 2px;
    }
  }
  
  .battery-level {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 10%;
    height: 100%;
    background: #30b455;
  
    &.warn {
      background-color: #EFAF13;
    }
  
    &.warn2 {
      background-color: #e84c09;
    }

    &.warn3 {
      background-color: #e81809;
    }
  
    &.alert {
      background-color: #e81309;
  
      &:after {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"%3E%3Cpath fill="%23e81309" d="M17.927 12l2.68-10.28c0.040-0.126 0.060-0.261 0.060-0.4 0-0.726-0.587-1.32-1.314-1.32-0.413 0-0.78 0.187-1.019 0.487l-13.38 17.353c-0.18 0.227-0.287 0.513-0.287 0.827 0 0.733 0.6 1.333 1.333 1.333h8.073l-2.68 10.28c-0.041 0.127-0.060 0.261-0.060 0.4 0.001 0.727 0.587 1.32 1.314 1.32 0.413 0 0.78-0.186 1.020-0.487l13.379-17.353c0.181-0.227 0.287-0.513 0.287-0.827 0-0.733-0.6-1.333-1.333-1.333h-8.073z"%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-size: 12px;
        /* Replace with your desired size */
        height: 20px;
        /* Replace with your desired size */
        width: 20px;
        /* Replace with your desired size */
        margin: 0px 0 0 -3px;
        content: '';
        display: inline-block;
        position: absolute;
        transform: rotate(90deg);
      }
    }
  }
  
  
  .list-item:hover .battery {
    border: 3px solid #ffffff;
  
    &:after {
      background: white;
  
    }
  
    &:before {
      border: 1px solid #000000;
  
    }
  }
  
  
  
  /*  if the batter is active then use this styling for the battery instead */
  .active {
    border: 3px solid #ffffff;
    width: 28px;
    /* Adjust the width to make it horizontal */
    height: 18px;
    /* Adjust the height to make it horizontal */
    padding: 2px;
    border-radius: 4px;
    position: relative;
    /* margin: 15px 0; */
  
    &:after {
      content: '';
      height: 12px;
      /* Adjust the height to make it horizontal */
      width: 3px;
      /* Adjust the width to make it horizontal */
      background: white;
      display: block;
      position: absolute;
      top: -0.2px;
      /* Adjust the position to make it horizontal */
      right: -5px;
      /* Adjust the position to make it horizontal */
      border-radius: 0 2px 2px 0;
    }
  
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid #000000;
      border-radius: 2px;
    }
  
  }
  
  
  /* -------- battery code  --------*/