.userManagementRoot {
  /* This centers the child element both horizontally and vertically */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  height: fit-content;
  /* Only needed for the home page*/
  /* padding: 20px 30px; */
  /* gap: 30px;   */
}

.breadcrumb-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}