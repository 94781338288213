.table-container {
    
    background-color: white;
    width: 100%;
    /* height: 100%; */
    height: fit-content ;
    justify-self: center;
    align-items: center;
    /* min-height: 1247px; */
    overflow-y: hidden;
    
    padding-bottom: 20px;
    box-shadow: 0px 0px 3px rgba(196, 196, 196);
    border-radius: 12px;
}

.logs {
    height: 100%; 
}

.logs-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 24px;
    padding: 0px 20px 0px 20px;
    height: fit-content;
    overflow: hidden;
    width: 100%;
}

.top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

}

.lineGraphTableContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px 20px 0px;
}

.table{
    width: 100%;
    height: 100%;
    background-color: red;
}

@media screen and (max-width: 1500px) {

    .table-container {
        width: fit-content;
       
        flex-direction: column;
        justify-self: left;
        background-color: white;
        
        /* height: 100%; */
        height: fit-content ;

        /* min-height: 1247px; */
        overflow-y: hidden;
        
        padding-bottom: 20px;
        box-shadow: 0px 0px 3px rgba(196, 196, 196);
        border-radius: 12px;
    }

    .logs-container {
        width: 800px;
    }
    
    .logs {
        height: 100%; 
    }
    
    .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    
    }
    
    .lineGraphTableContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        margin: 20px 0px 20px 0px;
    }
    
    .table{
        width: fit-content;
        height: 100%;
        background-color: red;
    }
    
}