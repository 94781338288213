.table {
    height: 94.3%;
}

.ModalIcon {
    width: 100px;
    height: 100px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
  }

/* 
.list-items ul {
    width: 100%;
    height: 100%;
    background-color: rgb(208, 208, 208);
    border: 2px solid rgb(229, 229, 229);
}

.list-items ul li {
    width: 80%;
    height: auto;
    background-color: #989898
} */

.settingIconimg {
    width: 20px;
    height: 20px;
    align-self: center;
    justify-self: center;
    padding: 0px;
}

/* Drop down menu styling from cog wheel in table */
.container {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff;
    padding: 8px;
    z-index: 1000;
    right: 20px
}

.container h3 {
    text-align: left;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    margin-left: 15px;

}

.list-container {
    display: flex;

    background-color: rgb(237, 237, 237);
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.container ul {
    list-style: none;
    padding-left: 30px;
    margin: 0px;
    padding: 0px;
}

.Search-container {
    align-items: flex-start;
}

.table-container {

    box-shadow: 0px .4px 3px rgba(0, 0, 0, 0.521);
    background-color: white;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-items: center;
    padding: 20px 0 20px 0;
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0 12px 12px 12px;
}


/* Role column cell styling */

.roleCell_container {

    width: fit-content;
    border-radius: 12px;
    padding: 4px 5px;

}

.VerifiedCell_image {

    height: 35px;

    margin-left: 15px;
    height: 35px;
}

/* @media screen and (max-width: 1500px) {
    .table-container {
        width: fit-content
    }

} */

.inviteUserForm-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tableTitleText{
    font-size: 15px;
    display: flex;
    color: #7f7f7f;
    cursor: pointer;
  }
  
  .tableValueTextBold{
      cursor: pointer;
      width: fit-content;
      font-weight: 500;
      font-size: 15px;
  }
  
  .tableValueText{
    cursor: pointer;
    width: fit-content;
    font-size: 15px;
  }
  
  .PillHeight{
    height: fit-content;
    font-size: 15px;
  }

@media screen and (max-width: 1500px) {

    .table-container {
        min-width: 700px;
        width: fit-content
    }

    .tableTitleText{
      font-size: 13px;
      display: flex;
      color: #7f7f7f;
      cursor: pointer;
    }

    .tableValueText{
      cursor: pointer;
      width: fit-content;
      
      font-size: 13px;
    }

    .tableValueTextBold{
      cursor: pointer;
      width: fit-content;
      font-weight: 500;
      font-size: 13px;
    }

    .PillHeight{
      height: fit-content;
      font-size: 12px;
    }

    .bottom-wrapper_divider {
      background-color: rgb(127, 127, 127);
  
      height: 37px;
      width: 1px;
  
      margin-left: 10px;
      margin-right: 10px;
    }

    
}