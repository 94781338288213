.pageContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
}


.container {
    width: 100%;
    height: fit-content;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust 150px as needed */
    cursor: pointer;
}