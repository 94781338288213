.DeviceManagerPage {
    width: 100%;
    height: 100%;
    /* padding: 12px; */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
}

.ModalIcon {
    width: 100px;
    height: 100px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
  }

.groupGrid-title {
    font-style: italic;
    text-decoration: underline;
    font-size: var(--font-Main-Title);

    text-decoration-skip-ink: auto; /* This property helps to avoid the underline being cut off */
    text-underline-offset: .3em;
    font-weight: 900;
    margin: 0;
}

.grid-card{
    width: 100%;
    border: 1px solid #d4d4d4;
    
    background-color: var(--Background-Primary);
    border-radius: 12px;
    padding: 12px;
    height: 100%;
}

.loadingSpinner-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.searchContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
}

.buttonContainer {
    display: flex;
    gap: 10px; /* Optional: Adds space between buttons */
}


.buttonViewType {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 20px;
}


.ExcelButton {
    height: 100px;
    font-size: 20px;
    width: 1000px;
}

.CategoryButton {
    height: 100px;
    font-size: 20px;
}

.SortOrderButton {
    height: 100px;
    font-size: 20px;
}

.AllSites {
    height: 100px;
    font-size: 20px;
}

.ButtonContainer2 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-right: 50px;
    gap: 10px;
}




@media (max-width: 1500px) {

    .searchContainer{
        display: flex;
        gap: 20px;
        width: 70%;
        justify-content: flex-start;
        align-items: center;
        height: fit-content;
    }

    .ButtonContainer2 {
        display: flex;
        width: 10%;
        justify-content: space-between;
        margin-bottom: 0px;
        margin-right: 50px;
        gap: 10px;
    }


  }