/* .appView-container{
display: flex;
flex-direction: column;
height: 100%;

} */



.appView-container {
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 30px;
}

.appview-controls1 {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;

}


.appview-controls2 {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;

}

.button {
    background-color: rgb(255, 255, 255);
    border: 1px solid white;
    border-radius: 12px;
    height: 65px;
    width: 65px;
    padding: 0px;
    margin: 0px;

}

.button:hover {
    transform: scale(1.1);
}


.ToggleEditPinView {
    background-color: rgb(255, 255, 255);
    border: 1px solid white;
    border-radius: 12px;
    height: 64px;
    width: 64px;
    padding: 0px;
    margin: 0px;
    box-shadow: 0px 0px 3px rgb(196, 196, 196);;
    cursor: pointer;
}

.ToggleEditPinView:hover {
    background-color: var(--Primary-Color);
    color: white;
    outline: 2px solid white;
}

.ToggleEditPinView:active {
   transform: scale(.95);
}

.ToggleEditPinViewActive{
    background-color: var(--Primary-Color);
    color: white;
    outline: 2px solid white;
 
 
    border-radius: 12px;
    height: 64px;
    width: 64px;
    padding: 0px;
    margin: 0px;
    box-shadow: 0px 0px 3px rgb(196, 196, 196);
}
.ToggleEditPinView:hover .img {
    filter: invert(0%);
}

.img {
    filter: invert(100%);
}

.activeImg{
    filter: invert(0%);
}


.PreviousPageButtonImg{
    filter: invert(100%);
}


@media screen and (max-width: 1500px) {

    .appView-container {
        position: relative;
        height: fit-content;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        gap: 5px;
    }

    
    .ToggleEditPinView {
        background-color: rgb(255, 255, 255);
        border: 1px solid white;
        border-radius: 8px;
        height: 40px;
        width: 40px;
        padding: 0px;
        margin: 0px;
    }

    .ToggleEditPinView:hover {
        background-color: var(--Primary-Color);
        color: white;
        outline: 2px solid white;
    }

    .ToggleEditPinView:active {
        transform: scale(.95);
    }

    .ToggleEditPinViewActive{
        background-color: var(--Primary-Color);
        color: white;
        outline: 2px solid white;

        border-radius: 12px;
        height: 56px;
        width: 56px;
        padding: 0px;
        margin: 0px;
        box-shadow: 0px 0px 3px rgb(196, 196, 196);
    }
    
    .ToggleEditPinView:hover .img {
        filter: invert(0%);
    }

    .img {
        filter: invert(100%);
    }

    .activeImg{
        filter: invert(0%);
    }


    .PreviousPageButtonImg{
        filter: invert(100%);
    }

    .appview-controls2 {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: flex-end;
    
    }
}