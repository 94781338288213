.input {
  background-color: var(--Background-Primary);
  border: none;
  /* border-radius: 10px; */
  width: 100%;
  height: fit-content;
  padding-left: 20px;
  font-weight: 400;
  font-size: var(--font-title);
  padding: 10px;

  border-bottom: 2px solid black;
}

.input::placeholder {
  /* color: rgba(67, 67, 67, 0.441); */
  color: rgb(188, 188, 188);
}

.input:focus {
  outline-color: var(--Primary-Color);
}


Input.invalid {
  background: var(--Validation-color);
  color: black;
  transition: all .3s;
}

/* 
@media (min-width: 768px) {
  Input {
    align-items: center;
    flex-direction: row;
  }

}   */