
/*CREATE A 2ND CSS FOR CARDS AND APPLY*/


.cardSources {
    /* width: 505px; */
    min-width: 400px;
    max-width: 400px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 12px;
    color: rgb(98, 98, 98);
    box-shadow: 0px 0px 3px rgb(196, 196, 196);
}

.card {
    /* width: 505px; */
    min-width: 400px;
    max-width: 400px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    border-radius: 12px;
    color: rgb(98, 98, 98);
    box-shadow: 0px 0px 3px rgb(196, 196, 196);
}

.headerBar{
    height: 15px;
    width: 100%;
    border-radius:12px 12px 0px 0px ;
    box-shadow: 1px 5px 5px;
}

 @media screen and (max-width: 1500px) {
    .cardSources {
        min-width: 300px;
        height: 100px;
    }

    .headerBar{
        height: 5px;
        width: 100%;
        border-radius:12px 12px 0px 0px ;
        box-shadow: 1px 5px 5px;
    }
} 