.EditPinContainer{
    width: 511px;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    
}

.EditPinContainer h2{ 
    margin: 0px;
}