.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 474px;
    /* margin: 22px; */
    padding: 22px;
    background-color: #1d1d1d;
    border-radius: 12px;
}

.Title {
    font-size: var(--font-Main-Title);
    font-weight: 500;
    margin: 20px;
    margin-top: 0px;
    color: rgb(255, 255, 255);
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 20px;
    margin: 0;
}

.input{
    background-color: rgba(255, 255, 255, 0);
    color: white;
    /* background-color: var(--Background-Primary); */
    border: none;
    /* border-radius: 10px; */
    width: 100%;
    height: fit-content;
    padding-left: 20px;
    font-weight: 400;
    font-size: var(--font-title);
    padding: 10px;
    border-bottom: 2px solid white;
}

.serviceLink {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
}

.extraLinks-list {
    list-style: none;
    padding: 0;
}

.extraLinks-list li {
    display: flex;
}

.extraLink {
    margin: 0px 15px;
    color: rgb(20, 22, 52);
    font-size: 16px;

}

.extraLink span {
    color: rgb(52, 20, 46);
    font-size: 16px;
    font-weight: 600;
}


.sectionTitle {
    background: rgb(255, 255, 255);
    width: fit-content;
    padding: 6px;
    font-style: italic;
    font-size: 18px;

    margin-top: 165px;
    margin-left: 100px;

    position: absolute;
    top: 0;
    left: 0;
}

.sectionTitle2 {
    background: rgb(255, 255, 255);
    width: fit-content;
    padding: 6px;
    font-style: italic;
    font-size: 18px;

    margin-top: 563px;
    margin-left: 100px;

    position: absolute;
    top: 0;
    left: 0;



}

.input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    height: fit-content;
    padding: 18px 10px;
    background-color: var(--Primary-Color-SiteView);
    /* border: 1px solid black; */
    border-radius: 12px;
    gap: 10px;
    position: relative;
    
}

.input-container .subTitle {
    position: absolute;
    padding: 5px;
    top: -2px;
    left: 16px;
    color: white;
}

.toggleInput {
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 12px;
    padding: 18px;
    align-items: center;
    background-color: var(--Primary-Color-SiteView);
    color: white;
    border: none;
}

.dropdown{ 
    background-color: rgba(255, 255, 255, 0);
    padding-top: 10px;
}

.img {
    width: 40px;
    height: 40px;
    filter: invert(100%);
  }
  

.submitButton {
    border-radius: 12px;
    border: none;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    align-self: flex-end;
    background-color: var(--Primary-Color);
    color: white;
    border: 1px solid var(--Primary-Color);
}

.submitButton:hover {
    background-color: #63d263;
}

.submitButton:active {
    scale: 0.97;
}


.activeSwitchType {
    background-color: var(--Primary-Color);
    color: white;
}

@media screen and (max-width: 2100px) {
    .container {
        width: 434px;
    }
}