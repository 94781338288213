.GroupCardContentLayout {

    width: 100%;
    min-height: 141px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;
}

.header-bar {

    color: #05aec8;
}

.top-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    position: relative;
}




.siteDetails-layout {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
}

.siteDetails-layout h3 {
    margin: 3px;
    font-size: var(--font-title);
    color: rgb(62, 62, 62);
}

.middle-wrapper {
    width: 100%;
    height: 100%;

    display: flex;

    justify-content: space-between;

}


.icons-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 91%;
    gap: 2px;
}

.tagName {
    /* font-weight: 600; */
    width: 80%;

    max-height: 100px;
    /* Adjust the height as needed */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    /* Adjust the number of lines to clamp */
}



.indicatorsCards-container {
    display: flex;
    flex-direction: flex;
    gap: 10px;
    align-items: flex-end;
}


.middle-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
}

.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;

    position: relative;
}

.bottomLeft-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    gap: 10px;

}

.userIcons {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

}

.ellipsis {
    width: fit-content;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;

}

.userIcon {
    background-color: var(--Primary-Color);
}

.arrowButton {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
}

.arrowButton:hover img {
    cursor: pointer;
    transform: translateX(10px);

}



.outputs {
    display: grid;


    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px;


}

.inputs {
    display: grid;


    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 5px;
}

.bottom-wrapper_divider {
    background-color: rgb(127, 127, 127);

    height: 47px;
    width: 1px;

    margin-left: 20px;
    margin-right: 20px;
}

.Icon {
    width: 30px;
    height: 30px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
}

/* .GroupCardContentLayout:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 23px;

} */

.GroupCardContentLayout:hover .arrowIcon {
    /* transform: translateX(10px); */
    /* Move the icon 10px to the right on active state */
}

/* @media screen and (max-width: 600px) {
    .siteDetails-layout h3 {
        font-size: 20px;
    }

    .userIcons {
        display: none;
    }

} */


/* Edit Automation Modal Page  */
.container {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 10px;

}

.container h4 {
    font-weight: 400;
}

.optionList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* align-items: center; */
    margin-bottom: 90px;
}

.listOption {
    background-color: var(--Primary-Color);
    border-radius: 12px;
    width: 100%;
    height: fit-content;
    color: white;
    padding: 12px 20px 12px 20px;

    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: none;

}

.listOption:hover {
    background-color: rgb(119, 119, 119);
}

.listOption:active {
    background-color: rgb(161, 161, 161);
}

.listOption p {
    margin: 0px;
}

.listOption h4 {
    margin: 0px;
    font-weight: 400;
}




.verticalFlex {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.horizontalFlex {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.RemoveButton {
    background-color: var(--Primary-Color);
    color: white;
}

.RemoveButton:hover {
    background-color: red;
}

.UpdateButton {
    background-color: var(--Primary-Color);
    color: white;
}

.UpdateButton:hover {
    background-color: rgb(99, 210, 99);
    color: white;
}

@media screen and (max-width: 1500px) {
    .GroupCardContentLayout {
        width: 100%;
        min-height: 100px;
        height: 80%;
        display: flex;
        flex-direction: column;
        padding: 25px 25px;
        padding-top: 8px;
        border-radius: 12px;
        text-decoration: none;
        /* gap: 10px; */
        cursor: default;
    }
    
    .header-bar {
    
        color: #05aec8;
    }
    
    .top-wrapper {
        width: 100%;
        height: 80%;
    
        display: flex;
        position: relative;
    }

    .subtitle {
        max-height: 100px;
        font-weight: 400;
        margin: 0px;
        font-size: 15px;
        width: fit-content;
    }

    .title {
        max-height: 100px;
        font-weight: 600;
        margin: 0px;
        font-size: 16px;
        width: fit-content;
    }
    
    .siteDetails-layout {
        width: 100%;
        height: 100%;
        font-size: 12px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 3px;
    }
    
    .siteDetails-layout h3 {
        margin: 2px;
        font-size: 16px;
        color: rgb(62, 62, 62);
    }
    
    .middle-wrapper {
        width: 100%;
        height: 100%;
        margin-top: 6px;
        display: flex;
    
        justify-content: space-between;
    
    }
    
    .PillHeight{
        height: fit-content;
        font-size: 12px;
    }
    
    .icons-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 91%;
        gap: 2px;
    }
    
    .tagName {
        /* font-weight: 600; */
        width: 100%;
        max-height: 100px;
        /* Adjust the height as needed */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        font-size: 12px;
        /* Adjust the number of lines to clamp */
    }
    
    .indicatorsCards-container {
        display: flex;
        flex-direction: flex;
        gap: 10px;
        align-items: flex-end;
    }
    
    .middle-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 10px;
    }
    
    .bottom-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 12px;
        position: relative;
    }
    
    .bottomLeft-wrapper {
        width: fit-content;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-self: center;
        gap: 10px;
    
    }
    
    .userIcons {
        width: fit-content;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    
    }
    
    .ellipsis {
        width: fit-content;
        height: 100%;
        display: flex;
        /* justify-content: center; */
        align-items: flex-end;
    
    }
    
    .userIcon {
        background-color: var(--Primary-Color);
    }
    
    .arrowButton {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
    }
    
    .arrowButton:hover img {
        cursor: pointer;
        transform: translateX(10px);
    
    }
    
    .outputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5px;
        row-gap: 5px;
    }
    
    .inputs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5px;
        row-gap: 5px;
    }
    
    .bottom-wrapper_divider {
        background-color: rgb(127, 127, 127);
        height: 47px;
        width: 1px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    .Icon {
        width: 20px;
        height: 20px;
        /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
        background-repeat: no-repeat;
        /* Set the transition duration */
        transition: transform 0.3s;
    }
    
    /* .GroupCardContentLayout:active {
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
        padding: 23px;
    
    } */
    
    .GroupCardContentLayout:hover .arrowIcon {
        /* transform: translateX(10px); */
        /* Move the icon 10px to the right on active state */
    }
    
    /* @media screen and (max-width: 600px) {
        .siteDetails-layout h3 {
            font-size: 20px;
        }
    
        .userIcons {
            display: none;
        }
    
    } */
    
    
    /* Edit Automation Modal Page  */
    .container {
        display: flex;
        flex-direction: column;
        width: 60%;
        height: fit-content;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 10px;
    
    }
    
    .container h4 {
        font-weight: 400;
    }
    
    .optionList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* align-items: center; */
        margin-bottom: 90px;
    }
    
    .listOption {
        background-color: var(--Primary-Color);
        border-radius: 12px;
        width: 100%;
        height: fit-content;
        color: white;
        padding: 12px 20px 12px 20px;
    
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: none;
    
    }
    
    .listOption:hover {
        background-color: rgb(119, 119, 119);
    }
    
    .listOption:active {
        background-color: rgb(161, 161, 161);
    }
    
    .listOption p {
        margin: 0px;
    }
    
    .listOption h4 {
        margin: 0px;
        font-weight: 400;
    }
    
    
    
    
    .verticalFlex {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    
    .horizontalFlex {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    .RemoveButton {
        background-color: var(--Primary-Color);
        color: white;
    }
    
    .RemoveButton:hover {
        background-color: red;
    }
    
    .UpdateButton {
        background-color: var(--Primary-Color);
        color: white;
    }
    
    .UpdateButton:hover {
        background-color: rgb(99, 210, 99);
        color: white;
    }
    
}