.customDropdown {
  background-color: var(--Background-Primary);
  border: none;
  border-radius: 10px;
  width: 100%;
  height: fit-content;


  font-weight: 400;
  font-size: var(--font-title);
  cursor: pointer;


  display: flex;

  justify-content: space-between;

} 


.dropdownOptions {
  list-style: none;
  overflow-y: scroll; /* Always show the vertical scrollbar */
  min-width: 440px;
  max-height: 400px;
  position: absolute;
  z-index: 2;
  border: 1px solid grey;
  background-color: var(--Background-Secondary);
  border-radius: var(--button-border-radius);
 
}

/* Optional: Style the scrollbar for better appearance (for Webkit browsers) */
.dropdownOptions::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.dropdownOptions::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
}

.dropdownOptions::-webkit-scrollbar-thumb {
  background: #888; /* Colour of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.dropdownOptions::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker colour when hovered */
}

.invalid {
  background: var(--Validation-color);
}

.selectedOption {
  /* background-color: aquamarine; */
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding: 10px;
}

.selectedOption h3 {
  color: rgb(0, 0, 0);
  margin: 0px;
  font-size: var(--font-title);
  font-weight: 400;
  color: black;
}

/* .dropdownOptions {
  min-width: 440px;
  height: fit-content;

  position: absolute;
  margin-top: 140px;

  z-index: 2;

  background-color: var(--Background-Secondary);
  border-radius: var(--button-border-radius);

  box-shadow: 0px 1px 5px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  align-items: center;


} */


.dropdownOptions li {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  width: 100%;

  padding: 10px;
  justify-content: flex-start;
  gap: 10px;
}

.dropdownOptions li:hover {
  /* background-color: var(--Highlight-Select); */
  background-color: var(--Primary-Color);
  color: white;
}


.dropdownArrowImage {
  width: var(--Icon-Size-Small);
  height: 100%;
  transform-origin: center;
  transition: transform 1s;
  margin-right: 10px;
}

/* Define a CSS animation for the rotation */
@keyframes rotate180 {
  to {
    transform: rotate(180deg);
  }
}

/* Apply rotation when the 'rotate' class is present */
.rotate .dropdown-arrow_image {
  animation: rotate180 0.5s forwards;
}


.color {
  background-color: aqua;
}


.img {
  width: 30px;
  height: 30px;
}

.dropdownOptions li:hover img {
  filter: invert(100%);
}