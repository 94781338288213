.notification-container {
    position: fixed;
    top: 108px;
    right: 11px;
    width: fit-content;
    max-width: 550px;    

    /* height: 40px; */
    background-color: white;

    border-radius: 14px;
    /* box-shadow: 0 2px 8px rgb(255, 255, 255); */
    z-index: 30;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    padding: 15px 10px;
    gap: 10px;
    box-shadow: 0px 0.5px 3px rgb(0, 0, 0);
}

.notification-Content{
    display: flex;
    align-items: center;
    gap: 20px;

}

.notification-container h4 {
    color: rgb(0, 0, 0);
    margin: 0px;
    font-weight: 400;
    font-size: 22px;
    text-align: left;
    margin-left: 48px;
}

.notification-Content h4{
    margin-left: 0px;
}

.notificationIcon {
    padding-left: 12px;
    /* filter: invert(70%); */
    width: fit-content;
    height: 18px;
    object-fit: contain;
}

.closeIcon {
    padding-left: 12px;
    cursor: pointer;
    filter: invert(70%);
    /* width: fit-content; */
    height: 13px;
    object-fit: contain;
    color: #ed3232a5;
}

/* .img {
    padding-right: 12px;
    height: auto;
    width: auto;
    filter: invert(100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(1);
} */