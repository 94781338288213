.activtyPage_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
}

.container {
    width: 100%;
    height: fit-content;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust 150px as needed */
}

.ModalIcon {
    width: 100px;
    height: 100px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
  }

.StatsContainer {
    max-width: 350px;
    width: 100%;
    height: fit-content;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: column;
    padding: 20px;
    
    border-radius: 10px;
    gap: 5px;
    box-shadow: 0px 0px 3px rgb(196, 196, 196);;
}

.StatsContainer h3 {
    font-size: 16px;
}

.StatsContainerIcon {
    width: 20px;
    height: 20px;
}

.StatsConatinerSubText {
    font-size: 13px;
}

@media screen and (max-width: 1500px) {
    .StatsContainer {
        max-width: 200px;
        width: 100%;
        height: fit-content;
        background-color: rgb(255, 255, 255);
    
        display: flex;
        flex-direction: column;
        padding: 20px;
        
        border-radius: 10px;
        gap: 5px;
        box-shadow: 0px 0px 3px rgb(196, 196, 196);;
    }
    
    .StatsContainer h3 {
        font-size: 13px;
    }
    
    .StatsContainerIcon {
        width: 25px;
        height: 25px;
    }
    
    .StatsConatinerSubText {
        font-size: 11px;
    }
}