.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin: 22px; */
    padding: 22px;
}

.ModalIcon {
    width: 100px;
    height: 100px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
  }
  
.Title {
    font-size: var(--font-Main-Title);
    font-weight: 500;
    margin: 20px;
    margin-top: 0px;
    color: rgb(73, 73, 73);
}

.label {
    position: absolute;
        top: -15px;
        left: 22px;
        background: white;
        padding: 6px;
}

.inputs-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px;
    width: 100%;
    gap: 10px;
    margin: 0;
    position: relative;
    align-items: flex-end;
}



.serviceLink {
    display: flex;
    margin: 5px 0;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
    width: 100%;
}

.extraLinks-list {
    list-style: none;
    padding: 0;
}

.extraLinks-list li {
    display: flex;
}

.extraLink {
    margin: 0px 15px;
    color: rgb(20, 22, 52);
    font-size: 16px;

}

.extraLink span {
    color: rgb(52, 20, 46);
    font-size: 16px;
    font-weight: 600;
}


.sectionTitle {
    background: rgb(255, 255, 255);
    width: fit-content;
    padding: 6px;
    font-style: italic;
    font-size: 18px;

    margin-top: 165px;
    margin-left: 100px;

    position: absolute;
    top: 0;
    left: 0;



}

.sectionTitle2 {
    background: rgb(255, 255, 255);
    width: fit-content;
    padding: 6px;
    font-style: italic;
    font-size: 18px;

    margin-top: 563px;
    margin-left: 100px;

    position: absolute;
    top: 0;
    left: 0;



}

.input-container {
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    width: 100%;
    height: fit-content;
    padding: 18px 10px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 12px;
    gap: 10px;

}

.toggle-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.button-container{
    display: flex;
    gap: 10px;
}

.submitButton {
    border-radius: 12px;
    border: none;
    height: fit-content;
    width: fit-content;
    padding: 10px;
}

.errorText{
    margin: 0px;
    color: red;
    font-weight: 400;
    font-size: 14px;
}