
.button {
    background: white;
    border-radius: 10px;
    border: none;

    height: fit-content;
    min-height: clamp(50px, 60px, 70px);
    max-height: 70px;
    /* min-width: 200px; */
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 18px;
    padding: 0px 20px;
    cursor: pointer;
    /* transition: width 0.5s ease; */
}

.button:hover{
    background-color: var(--Primary-Color);
    color: white;
}

.button:hover{
    background-color: var(--Primary-Color);
    color: white;
}
