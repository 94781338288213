.loadingSpinner-container{
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1500px) {
    .loadingSpinner-container {
        position: fixed; /* Ensures it stays above content and on the viewport */
        top: 50%; /* Center vertically */
        right: 30%; /* Offset to the right by 50% */
        transform: translate(50%, -50%); /* Adjusts the spinner to be visually centered */
        width: auto; /* Adjust to the spinner size */
        height: auto; /* Adjust to the spinner size */
        z-index: 1000; /* Ensure it’s above other content */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}