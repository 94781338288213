.output {
    width: 22px;
    height: 22px; 
    border-radius: 50%;
}

@media screen and (max-width: 1500px) {
    .output {
        width: 18px;
        height: 18px; 
        border-radius: 50%;
    }
}


