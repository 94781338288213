.StatusIndicatorCard {
    width: 90px;
    height: 30px;

    padding: 0px 10px 0px 10px;
    background-color: #bababa;
    border-radius: var(--button-border-radius);
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--font-normal);
    background: #777777;
    font-size: 12px;
}

.inactive {
    /* Styles for inactive state */
    background-color: var(--Inactive-color);
}

.partiallyActive {
    /* Styles for partially active state */

    background: rgb(255, 132, 0);

}

.active {
    /* Styles for active state */
    background-color: var(--Active-color);
}

.suspended {
    background: rgb(0, 0, 0);
}

.loading {
    background: #e0e0e0;
}

@media screen and (max-width: 600px) {
    .StatusIndicatorCard {
        width: 60px;
        padding: 6px;
        font-size: var(--font-Mobile);
    }

}