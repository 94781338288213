.container {
    display: flex;
    gap: 10px;
    background-color: var(--Primary-Color);
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    color: white;
}

.container label {
    min-width: 65px;
    text-align: center;
}

/* TimePicker.css */

.time-picker-container {
    margin-bottom: 20px;
}

.time-input {
    /* Example custom styling */
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    /* Remove default focus outline */
    width: 150px;
    /* Adjust width as needed */
}

.time-input:hover,
.time-input:focus {
    border-color: #007bff;
    /* Example border color change on hover/focus */
}