.infoBox {
    display: flex;

    height: 70px;
    width: 400px;
}

.nextButton {
    display: flex;
    /* position: absolute; */
    /* right: 7px; */
    /* top: 70px; */
    text-align: center;
    border-radius: 50%;
    border: 1px solid black;
    width: 35px;
    height: 35px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: white;
}

.nextButton:disabled{
    background: none;
    border: 1px solid black;
}

.backButton {
    display: flex;
    /* position: absolute;
    left: 7px;
    top: 70px; */
    text-align: center;
    border-radius: 50%;
    border: 1px solid black;
    width: 35px;
    height: 35px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: white;
}

.SaveButton {
    display: flex;
    /* position: absolute; */
    /* right: 7px; */
    /* top: 70px; */
    text-align: center;
    border-radius: 8px;
    border: 1px solid black;
    width: fit-content;
    padding: 9px 10px;
    height: fit-content;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    background-color: white;
}

.SaveButton:disabled{
    background: none;
    border: 1px solid black;
}

.backButton:hover {
    padding: 7px;
    font-size: 22px;
}

.backButton:active {
    background-color: var(--Primary-Color);
    color: white;
}


.SaveButton:hover,
.SaveButton:active {
    background-color: rgb(131, 219, 131);
    color: white;
}