.GroupCardContentLayout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    text-decoration: none;
    gap: 10px;
}

.top-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
}

.groupDetails-layout {
    width: 100%;
    height: 100%;


    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.groupDetails-layout h3 {
    margin: 3px;
    font-size: var(--font-title);
    color: rgb(62, 62, 62);
}

.groupName {
    font-weight: 600;
}


.middle-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
}

.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.bottomLeft-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    gap: 10px;

}

.userIcons {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

}

.ellipsis {
    width: fit-content;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;

}

.userIcon {
    background-color: var(--Primary-Colour);
}

.arrowButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.arrowIcon {
    width: 60px;
    height: 60px;
    /* background-image: url('../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;

    /* Set the transition duration */
    transition: transform 0.3s;

}

.GroupCardContentLayout:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 23px;

}

.GroupCardContentLayout:hover .arrowIcon {
    transform: translateX(10px);
    /* Move the icon 10px to the right on active state */
}

@media screen and (max-width: 600px) {
    .groupDetails-layout h3 {
    font-size: 20px;
    }

    .userIcons{
        display: none;
    }

}