.PinView-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 0px 3px rgba(196, 196, 196);
    border-radius: 12px;
    background-color: #1d1d1d;
    gap: 0px;
    
}

.overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.47);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* Adjust z-index to position the overlay element above the child div */
    z-index: 1;
    border-radius: 12px;
    
    /* pointer-events: none; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.text-container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(54, 54, 54);
    padding: 5px;
    border-radius: 12px;
    border: 2px solid red;
    /* margin-bottom: 70px; */
    width: fit-content;
    z-index: 12;
}


.text-container1 h2 {
    margin: 10px;
    color: rgb(255, 255, 255);

}


.message-container {
    height: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text-container2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(72, 72, 72, 0.722);
    padding: 5px;

    border: 1px dashed white;
    /* margin-bottom: 100px; */
    width: fit-content;
    z-index: 12;
}


.text-container2 h2 {
    margin: 10px;
    color: rgb(255, 255, 255);

}



@media screen and (max-width: 1500px) {
    .PinView-container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-shadow: 0px 0px 3px rgba(196, 196, 196);
        border-radius: 12px;
        background-color: #1d1d1d;
        gap: 0px;
    }
}