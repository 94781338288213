.table {
    width: 100%;
    table-layout: auto;
    height: 100%;
    /* padding-bottom: 20px; */
    border-radius: 12px;
    box-shadow: 0px 0px 3px rgba(196, 196, 196);
}

.card2 {
    min-width: 1500px;
    width: 100%;
    height: 100%;
}

.tableCardLayoutUI-wrapper {
 overflow-x: auto; /* Enables horizontal scrolling if content overflows */
  max-width: 100%; /* Prevents content from exceeding the container */
  display: block; 
    
    /* padding: 20px 30px; */
}

.top-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: aqua; */
    /* gap: 5px; */
    padding: 12px 16px
}

.top-wrapper h1 {
    font-size: 18px;
    font-weight: 500;
}
.top-wrapper h3 {
    font-size: 14px;
    font-weight: 400;
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-container {
    /* height: 100%; */
    max-height: 700px;
    width: 100%;
    overflow-y: scroll;
    /* padding-bottom: 25px; */
    /* background-color: red; */
    border-radius: 12px;

}

.tableTitleText{
  font-size: 15px;
  display: flex;
  color: #7f7f7f;
  cursor: pointer;
}

.tableValueTextBold{
    cursor: pointer;
    width: fit-content;
    font-weight: 500;
    font-size: 15px;
}

.tableValueText{
  cursor: pointer;
  width: fit-content;
  font-size: 15px;
}

.table-container2 {
    border-spacing: 0;
    width: 100%;

    display: table;
    border-radius: 15px;
    padding: 10px 0px;


    height: 0px;
    max-height: 20px;
    font-weight: 900;

    /* position: relative; */

}

.loadingSpinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
/* Settingn the defaulft size of the table card if the screen width is greather than 1470px, if not then 100% */
/* @media screen and (min-width: 1470px) {
    .card {

        width: 1300px;
        height: 600px;
    }

} */

.PillHeight{
  height: fit-content;
  font-size: 14px;
}



.dropDown-Icon {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 0;
    filter: grayscale(100%);
    /* filter: grayscale(100%); */

}

.Icon {
  width: 100px;
  height: 100px;
  /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
  background-repeat: no-repeat;
  /* Set the transition duration */
  transition: transform 0.3s;
}




.fade-in-from-top {

    height: 220px;
    animation: fadeInFromTop 0.8s ease-out forwards;
}

.with-delay_1 {
    animation-delay: 0.2s;
}

@keyframes fadeInFromTop {
    to {
        height: 800px;
    }
}

.outputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px
}

.inputs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 5px;
}

.bottom-wrapper_divider {
    background-color: rgb(127, 127, 127);

    height: 47px;
    width: 1px;

    margin-left: 20px;
    margin-right: 20px;
}


.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;
}

.EyeSpyFX {
    background: rgb(100, 149, 237);
  }
  
  .RESURE {
    background: rgb(75, 0, 130);
  }
  
  .PARFAS {
    background: rgb(0, 0, 0);
  }
  
  .PORTAL_SECURITY {
    background: rgb(0, 0, 139);
  }
  
  .SOLO {
    background: rgb(178, 34, 34);
  }
  
  .KNOWIO{
    background: rgb(20, 155, 8);
  }
  
  .CPS{
    background: rgb(8, 155, 121);
  }
  
  .CSC_SECURITY{
    background: rgb(8, 150, 155);
  }
  .EES{
    background: rgb(94, 8, 155);
  }
  
  .defaultCompany {
    background: rgb(110, 110, 110);
  }


.circular-checkbox {
    display: none;
  }
  
  /* Create a custom circular checkbox */
  .custom-checkbox {
    width: 30px;
    height: 30px;
    border: 2px solid #05aec8;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  /* The circle inside the custom checkbox when checked */
  .circular-checkbox:checked + .custom-checkbox::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #05aec8;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 1500px) {

    .tableTitleText{
      font-size: 13px;
      display: flex;
      color: #7f7f7f;
      cursor: pointer;
    }

    .tableValueText{
      cursor: pointer;
      width: fit-content;
      
      font-size: 13px;
    }

    .tableValueTextBold{
      cursor: pointer;
      width: fit-content;
      font-weight: 500;
      font-size: 13px;
    }

    .PillHeight{
      height: fit-content;
      font-size: 12px;
    }

    .bottom-wrapper_divider {
      background-color: rgb(127, 127, 127);
  
      height: 37px;
      width: 1px;
  
      margin-left: 10px;
      margin-right: 10px;
  }
}