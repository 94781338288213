.SearchCard {
  /* This controls the width and height of the SearchCard */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* align-items: center; */
  background: rgb(96, 96, 96, 0);

  /* Size of searchCard*/
  height: auto;
  width: 100%;
  
 
}

.Search-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 0 12px 0 12px;
  color: black;
  height: 500px;
  max-height: 300px;
  width: 100%;
  /* max-width: 900px; */
}

/* for the Search filter Headering */
.headers {
  margin: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;

}


.header-data {
  margin-top: 20px;

}

.header-data h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  height: 40px;

}


.header-data h2:hover {
  border-bottom: 2px solid black;
}


.header-data_h2Active {
  border-bottom: 2px solid black;
}

.category {
  margin: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;

}

.category {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  height: 40px;
}

.category.active {
  border-bottom: 2px solid black;
}


.searchList-Results {

  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  
  align-items: center; */
  text-align: center;
  height: auto;
  max-height: 300px;
  width: 100%;
  max-width: 1150px;
  color: rgb(0, 0, 0);
  padding: 15px 0px;

  margin-top: 9px;

  /* background-color: rgb(255, 255, 255); */
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  border-radius: 0;
  border-radius: 8px;
  gap: 20px;

  z-index: 100;

}

.searchList-Results h2 {
  margin: 0;
  padding: 2px;
  padding-left: 10px;

}

.searchList-Results:active {
  transform: scale(1.1);
}

::-webkit-scrollbar {
  width: 0em;
}


.list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 12px 0 12px;
  color: black;
  height: auto;
  max-height: 300px;
  width: 100%;
  /* max-width: 900px; */


}

.list-item h3:first-of-type {
  /* border-left: 1px solid hsl(0, 0%, 0%); */
  padding-left: 10px;
  /* background-color: aqua; */
  /* width: 100px; */
}


.list-item h3 {
  /* border-right: 1px solid hsl(0, 0%, 0%); */
  padding-right: 10px;
  width: 240px;
  /* width: auto; */

}

.list-item h3:nth-child(2) {
  width: 350px;
}

.list-item h3:nth-child(4) {
  width: 320px;
}

.list-item:hover {
  background-color: rgba(101, 97, 154, 0.573);
  border-radius: 3px;
  color: rgb(255, 255, 255);

}

.list-item:active {
  /* transform: scale(1.1); */
  padding: 0 10px 0 10px;
  border-color: 1px solid hsl(0, 0%, 0%);

}

.list-item h3:active {

  border-color: 1px solid hsl(0, 0%, 0%);

}

.searchBar {
  border-radius: 8px;
  border: 0px;
  width: 800px;
  height: 65px;
  font-size: 20px;
  padding-left: 60px;
  /* Adjust the size of the icon */
  background-image: url('../../assets/portalAssets/searchbarIconB.png');
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

.searchBar:focus {
  outline: 2px solid #290a4c;
}