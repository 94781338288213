.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: left;
    height: fit-content;
}

.form button {
    width: fit-content;
}

.filters {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    
}