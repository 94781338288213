.customDropdown {
    background-color: white;
    border: none;
    border-radius: 10px;
    width: 110px;
    height: fit-content;
    border: 1px solid rgb(196, 196, 196);
    /* padding-left: 20px; */
    /* padding: 10px; */
    font-weight: 400;
    font-size: var(--Button-font-size);
    cursor: pointer;
    height: 37px;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

    position: relative;
}

.invalid {
    background: var(--Validation-color);
}

.selectedOption {
    /* background-color: aquamarine; */
    width: 100%;
    height: 100%;
    
    

    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedOption h3 {
    color: rgb(0, 0, 0);
    margin: 0px;
    font-size: var(--Button-font-size);
    font-weight: 400;
    
    width: fit-content;

}

.dropdownOptions {
    width: 110px;
    height: fit-content;
    /* max-height: 200px; */
    position: absolute;
    margin-top: 297px;
    left: 0px;
    z-index: 2;

    background-color: var(--Background-Secondary);
    border-radius: var(--button-border-radius);
    /* padding: 10px; */
    box-shadow: 0px 0px 10px rgb(196, 196, 196);
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    align-items: center;

}

.dropdownOptions li {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
}

.dropdownOptions li:hover {
    background-color: var(--Primary-Color);
    color: white;
}

.dropdownArrowImage {
    width: var(--Icon-Size-Small);
    height: 100%;
    transform-origin: center;
    transition: transform 1s;
    /* margin-right: 10px; */
    width: 25px;
    height: 25px;
}

/* Define a CSS animation for the rotation */
@keyframes rotate180 {
    to {
        transform: rotate(180deg);
    }
}

/* Apply rotation when the 'rotate' class is present */
.rotate .dropdown-arrow_image {
    animation: rotate180 0.5s forwards;
}

.color {
    background-color: aqua;
}

.img {
    width: 60px;
    height: 60px;
}

.dropdownOptions li:hover img {
    filter: invert(100%);
}