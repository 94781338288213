.page-layout{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgb(20, 22, 52);  
}

.container{
    display: flex;
    height: 650px
}
.card{
    border-radius: 0 8px 8px 0;
    height: 100%;
}

.image{
    border-radius: 12px 0  0 12px;
    
    height: 100%;
    width: 690px;
    /* object-fit: fill; */
    
}

