.notification-container {
    top: 7.7vh;
    right: 11px;
    width: 100%;
    /* max-width: 550px; */
    height: fit-content;
    border-radius: 14px;
    /* box-shadow: 0 2px 8px rgb(255, 255, 255); */
    z-index: 30;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    gap: 10px;
    /* box-shadow: 0px 0.5px 3px rgb(0, 0, 0); */
}

.notification-container h4 {
    color: rgb(255, 255, 255);
    margin: 0px;
    font-weight: 400;
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.top{
    display: flex;
    gap: 5px;
}

.top h4{
    font-weight: 600;
}

.notificationIcon {
    /* padding-left: 12px; */
    filter: invert(100%);
    width: fit-content;
    height: 18px;
    object-fit: contain;
}

.closeIcon {
    padding-left: 12px;
    cursor: pointer;
    /* filter: invert(70%); */
    width: fit-content;
    height: 13px;
    object-fit: contain;
}

/* .img {
    padding-right: 12px;
    height: auto;
    width: auto;
    filter: invert(100%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(1);
} */