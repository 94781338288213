.grid-ul {
    width: 100%;
    /* padding: 10px; */
    display: grid;
  
    column-gap:0px;
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* grid-template-columns: 450px 450px 450px 450px 450px; */
    /* grid-template-columns: repeat(5, auto); */
    /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    row-gap: 30px;
    /* padding-right: 20px; */
    /* padding-bottom: 20px; */
    /* overflow: auto; */
  }
  
  .grid-li {
    display: flex;
    width: fit-content;
  }


/*CREATE A 2ND CSS FOR CARDS AND APPLY*/


.cardSources {
  /* width: 505px; */
  min-width: 400px;
  max-width: 400px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  border-radius: 12px;
  color: rgb(98, 98, 98);
  box-shadow: 0px 0px 3px rgb(196, 196, 196);
}

.card {
  /* width: 505px; */
  min-width: 400px;
  max-width: 400px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  border-radius: 12px;
  color: rgb(98, 98, 98);
  box-shadow: 0px 0px 3px rgb(196, 196, 196);
}

.headerBar{
  height: 15px;
  width: 100%;
  border-radius:12px 12px 0px 0px ;
  box-shadow: 1px 5px 5px;
}

@media screen and (max-width: 1500px) {
  .cardSources {
      min-width: 300px;
      height: 100px;
  }

  .headerBar{
      height: 5px;
      width: 100%;
      border-radius:12px 12px 0px 0px ;
      box-shadow: 1px 5px 5px;
  }
} 
  
  
  /* @media screen and (max-width: 1500px) {
    .grid-ul {
    
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (max-width: 1700px) {
    .grid-ul {
    
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 1205px) {
    .grid-ul {
    
      grid-template-columns: repeat(1, 1fr);
    }
  } */
  
  
  @media screen and (max-width: 1500px) {
    .grid-ul {
      width: 100%;
      /* padding: 10px; */
      display: grid;
      column-gap:10px;
/*     
      grid-template-columns: auto auto auto auto auto; */
      /* grid-template-columns: 450px 450px 450px 450px 450px; */
      /* grid-template-columns: repeat(5, auto); */
      /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
      grid-template-columns: repeat(2, 1fr);
      /* grid-template-columns: repeat(auto-fill, minmax(200px, 2fr)); */
      row-gap: 10px;
      /* padding-right: 20px; */
      /* padding-bottom: 20px; */
      /* overflow: auto; */
    }
    
    .grid-li {
      display: flex;
      width: fit-content;
    }
  
    /* .grid-ul {
      background-color: aquamarine;
      width: fit-content;
    }
  
    .grid-li {
      justify-content: center;
    } */

  }