.dropdown-container{
    height: 20px;
    width: 150px;
    padding: 0;
    padding: 20px;
}


.dropdown {
    /* display: none; */
    border: unset;
    font-size: 16px;
    background-color: #00000000;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
}


.selected-option {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    margin-left: 8px;
}

.arrow.up {
    transform: rotate(180deg);
}

.options {
    display: none;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid rgb(196, 196, 196);
    top: 100%; /* Adjust this value as needed */
    border-radius: 0 0 0px 5px;
    position: absolute;
    top: 100%;
}



.options li {
    padding: 10px;
    cursor: pointer;
}

.options li:hover {
    background-color: #f1f1f1;
}
