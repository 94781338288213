.button {
  font: inherit;
  border: 1px solid #010101;
  background: #3f3f3f;
  color: white;
  /* padding: 0.75rem 3.5rem; */
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
  width: 200px;
  height: 50px;
  font-weight: 600;
  font-size: 18px;

}

.button:hover {
  transform: scale(1.1);
  background: #07ab90;
  border-color: #000000;
  
}

.button:active {
  background: #07ab90;
  border-color: #000000;
 
}

.button:focus {
  outline: none;
}

/* 
button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
} */